import { shallowEqual, useSelector } from 'react-redux';

var useSocial = function useSocial() {
  var _useSelector = useSelector(function (state) {
    return {
      unreadMessagesCount: state.notifications.unreadMessagesCount
    };
  }, shallowEqual),
      unreadMessagesCount = _useSelector.unreadMessagesCount;

  return {
    count: unreadMessagesCount
  };
};

export default useSocial;