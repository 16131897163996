import { useSelector, shallowEqual } from 'react-redux';
import { AUTH_TYPES } from '../../../utils/authFlow/constants';
import { INVITE_FRIENDS_FEATURE_STATUS } from '../constants';
import { useGetFriendsInviteQuery } from '../../../state/query/friendsInvite';

var useFriendsInvite = function useFriendsInvite() {
  var _data$friendsInviteFe, _data$friendsInviteFe2, _data$friendsInviteFe3, _data$friendsInviteFe4, _data$friendsInviteFe5, _data$friendsInviteFe6, _data$friendsInviteFe7, _data$friendsInviteFe8;

  var _useSelector = useSelector(function (state) {
    var _state$token$lastLogi;

    return {
      loginMethod: (_state$token$lastLogi = state.token.lastLoginInfo) === null || _state$token$lastLogi === void 0 ? void 0 : _state$token$lastLogi.loginMethod,
      friendsInviteAssets: state.assetsPackage.assets.FRIENDS_INVITE_FEATURE
    };
  }, shallowEqual),
      loginMethod = _useSelector.loginMethod,
      friendsInviteAssets = _useSelector.friendsInviteAssets;

  var _useGetFriendsInviteQ = useGetFriendsInviteQuery({
    requestAssetsData: true
  }, {
    forceRefetch: true
  }),
      data = _useGetFriendsInviteQ.data;

  return {
    friendsInviteAssets: friendsInviteAssets,
    friendsInviteFeatureState: data === null || data === void 0 ? void 0 : data.friendsInviteFeatureState,
    isGuestUser: loginMethod === AUTH_TYPES.GUEST,
    hasActiveState: (data === null || data === void 0 ? void 0 : (_data$friendsInviteFe = data.friendsInviteFeatureState) === null || _data$friendsInviteFe === void 0 ? void 0 : (_data$friendsInviteFe2 = _data$friendsInviteFe.inviteSetup) === null || _data$friendsInviteFe2 === void 0 ? void 0 : _data$friendsInviteFe2.friendsInviteGameplayStatus) === INVITE_FRIENDS_FEATURE_STATUS.RUNNING,
    hasComingSoonState: (data === null || data === void 0 ? void 0 : (_data$friendsInviteFe3 = data.friendsInviteFeatureState) === null || _data$friendsInviteFe3 === void 0 ? void 0 : (_data$friendsInviteFe4 = _data$friendsInviteFe3.inviteSetup) === null || _data$friendsInviteFe4 === void 0 ? void 0 : _data$friendsInviteFe4.friendsInviteGameplayStatus) === INVITE_FRIENDS_FEATURE_STATUS.COMING_SOON,
    limitReached: (data === null || data === void 0 ? void 0 : (_data$friendsInviteFe5 = data.friendsInviteFeatureState) === null || _data$friendsInviteFe5 === void 0 ? void 0 : (_data$friendsInviteFe6 = _data$friendsInviteFe5.inviteLink) === null || _data$friendsInviteFe6 === void 0 ? void 0 : _data$friendsInviteFe6.maxRedeems) - (data === null || data === void 0 ? void 0 : (_data$friendsInviteFe7 = data.friendsInviteFeatureState) === null || _data$friendsInviteFe7 === void 0 ? void 0 : (_data$friendsInviteFe8 = _data$friendsInviteFe7.inviteLink) === null || _data$friendsInviteFe8 === void 0 ? void 0 : _data$friendsInviteFe8.redeemsCount) <= 0
  };
};

export default useFriendsInvite;