import Logger from '../../../lib/analytics/logger';
import { WELCOME_NEWS_EVENT_TYPES } from '../constants/events';
import { EVENT_ACTIONS, EVENT_DOMAINS, EVENT_SCREENS, EVENT_WIDGETS } from '../../../lib/analytics/constants';
import { EVENT_TYPES } from '../../../lib/analytics/constants';
export var sendWelcomeNewsEvent = function sendWelcomeNewsEvent(eventType, payload) {
  switch (eventType) {
    case WELCOME_NEWS_EVENT_TYPES.DISMISS_WELCOME_NEWS:
      Logger.sendEvent(EVENT_TYPES.UI_EVENT, {
        action: EVENT_ACTIONS.PRESS,
        domain: EVENT_DOMAINS.WELCOME_NEWS,
        screen: EVENT_SCREENS[EVENT_DOMAINS.GENERAL].LOBBY,
        widget: EVENT_WIDGETS[EVENT_DOMAINS.GENERAL].DISMISS_BUTTON,
        welcomeNewsAction: payload
      });
      break;

    case WELCOME_NEWS_EVENT_TYPES.PRESS_WELCOME_NEWS:
      Logger.sendEvent(EVENT_TYPES.UI_EVENT, {
        action: EVENT_ACTIONS.PRESS,
        domain: EVENT_DOMAINS.WELCOME_NEWS,
        screen: EVENT_SCREENS[EVENT_DOMAINS.GENERAL].LOBBY,
        widget: EVENT_WIDGETS[EVENT_DOMAINS.GENERAL].ACCEPT_BUTTON,
        welcomeNewsAction: payload
      });
      break;

    default:
      break;
  }
};