import React from 'react';
import useSocial from 'shared/screens/Social/hooks/useSocial';
import Tab from '../../components/Tab/Tab';
import Messages from './components/Messages/Messages';
import FriendsInvite from './components/FriendsInvite/FriendsInvite';
import SocialHeaderTab from './components/SocialHeaderTab/SocialHeaderTab';
import { TEXT_KEY, getText } from 'shared/utils/localization';

const Social = props => {
  const { count } = useSocial();

  return (
    <Tab>
      <Tab.Navigator headerComponent={SocialHeaderTab}>
        <Tab.Screen component={<Messages {...props} />} title={getText(TEXT_KEY.SOCIAL_MESSAGES_TITLE)} count={count} />
        <Tab.Screen component={<FriendsInvite />} title={getText(TEXT_KEY.SOCIAL_FRIENDS_INVITE_TITLE)} />
      </Tab.Navigator>
    </Tab>
  );
};

Social.AsModalProps = {
  dialogClassName: 'gtp-modal-social',
};

export default Social;

