import React, { useEffect } from 'react';
import SStart from 'shared/screens/Start';
import { getText, TEXT_KEY } from 'shared/utils/localization';
import RouteParamConstants from 'shared/utils/routeParamConstants';
import TermsModalWrapper from '../../components/TermsModal/TermsModalComponents/TermsModalWrapper';
import ResetPassword from '../ResetPassword';
import { ButtonContainer, SplashScreen, StartScreenWrapperMiddleDesign } from './styledComponents';
import ENVIRONMENT_VARIABLES from 'shared/utils/environmentVariables';

const MiddleLayout = props => {
  useEffect(() => {
    if (props.navigation.getParam(RouteParamConstants.PARAM_ACTION)) {
      _handleActionRedirect();
    }
    if (props.navigation.getParam(RouteParamConstants.PARAM_TRACKING_URL_AFF_ID)) {
      let aff_Id = props.navigation.getParam(RouteParamConstants.PARAM_TRACKING_URL_AFF_ID);
      Array.isArray(aff_Id) ? props.setUserAffId(aff_Id[0]) : props.setUserAffId(aff_Id);
    }
  }, []);

  const _handleActionRedirect = () => {
    window.location.assign(props.navigation.getParam(RouteParamConstants.PARAM_ACTION));
  };

  return (
    <SStart
      navigation={props.navigation}
      render={args => {
        const { closeModal: argsCloseModal, recoverPasswordResponse } = args;

        return (
          <SplashScreen className="SplashScreen">
            <ResetPassword
              isVisible={recoverPasswordResponse.visible}
              closeModal={argsCloseModal}
              zIndex={101}
              navigation={props.navigation}
              invalid={recoverPasswordResponse.invalid}
              errorMessage={recoverPasswordResponse.message}
              recoverId={recoverPasswordResponse.id}
              recoverToken={recoverPasswordResponse.token}
              getTextFunc={getText}
              textKeys={TEXT_KEY}
              TermsModalWrapper={TermsModalWrapper}
            />
            <StartScreenWrapperMiddleDesign>
              <img src="/comingsoon.png" alt="coming" />
              <ButtonContainer>
                <a target="_blank" href={ENVIRONMENT_VARIABLES.ANDROID_STORE_URL}>
                  <img src="/android.png" alt="coming" />
                </a>
                <a target="_blank" href={ENVIRONMENT_VARIABLES.APPLE_STORE_URL}>
                  <img src="/ios.png" alt="coming" />
                </a>
              </ButtonContainer>
            </StartScreenWrapperMiddleDesign>
          </SplashScreen>
        );
      }}
    />
  );
};

MiddleLayout.path = '';

export default MiddleLayout;

