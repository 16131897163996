import React, { Component } from 'react';
import Animated from 'animated/lib/targets/react-dom';
import ImageBW from '../../components/ImageBoundsWrapper';

import ENVIRONMENT_VARIABLES from 'shared/utils/environmentVariables';
import SBonusLinkMessage from 'shared/screens/BonusLinkMessage';
import { getText, TEXT_KEY } from 'shared/utils/localization';
import ThemeContext from 'shared/context/ThemeContext';
import Button from '../../components/Button/Button';

const COINS = 'coins';

export default class BonusLinkMessage extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    const themeContext = this.context;

    return (
      <SBonusLinkMessage
        {...this.props}
        render={
          /**
           *  @param {Object} args
           *  @param {String[]} args.apiRootList
           */
          args => {
            const { redeem, format } = args;
            if (!redeem) {
              return (
                <>
                  <div className="ModalBackground hideBefore">
                    <ImageBW
                      className="ModalBackgroundAsset"
                      as={Animated.div}
                      source={require(asset`Modal_Backing_9Slice@3x.png`)}
                      ibw={require(asset`Modal_Backing_9Slice.ibw`)}
                      widthScale={0.5}
                    />
                  </div>
                  <div className="ModalCloseButton" onClick={this.props.dismissModal}>
                    <ImageBW
                      source={require(asset`CloseButton@3x.png`)}
                      ibw={require(asset`CloseButton.ibw`)}
                      manualAssetScale={3}
                      style={{ objectFit: 'contain', height: '100%', width: '100%' }}
                      innerStyle={{ cursor: 'pointer' }}
                      alt="Close"
                    />
                  </div>
                  <div style={{ position: 'relative', flex: 1 }}>
                    <div
                      className={`gradientText ${ENVIRONMENT_VARIABLES.APPLICATION_TARGET}`}
                      data-label="SORRY"
                      style={{
                        lineHeight: 1,
                        fontSize: '4vmin',
                        fontWeight: '600',
                        margin: '0 0 3vmin 0',
                        position: 'relative',
                        display: 'flex',
                        justifyContent: 'center',
                      }}
                    >
                      <span>{getText(TEXT_KEY.BONUS_LINK_MESSAGE_INVALID_TITLE)}</span>
                      <img
                        src={require(asset`images/Purchase_Flow_Assets/Light_Divider@3x.png`)}
                        style={{ position: 'absolute', width: '100%', top: '100%', pointerEvents: 'none' }}
                        alt=""
                      />
                    </div>
                    <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                      <img
                        style={{ width: '9%', zIndex: 1, margin: '1rem 0' }}
                        src={require(asset`images/Purchase_Flow_Assets/Warning_Symbol@3x.png`)}
                        alt="Warning Symbol"
                      />
                      <span style={{ fontWeight: '500' }}>
                        {getText(TEXT_KEY.BONUS_LINK_MESSAGE_INVALID_DESCRIPTION)}
                      </span>
                      <Button
                        label={getText(TEXT_KEY.OK)}
                        onClick={this.props.dismissModal}
                        imageStyle={{ width: '25%', marginTop: '2rem', marginBottom: '1rem' }}
                        imageSource={require(asset`RoundedRect_Primary@3x.png`)}
                        containerStyle={{ position: 'relative', width: '100%', height: '100%', margin: 20 }}
                        textStyle={{ position: 'relative', top: '0.4rem' }}
                      />
                    </div>
                  </div>
                </>
              );
            }

            return (
              <div style={themeContext.BonusLinkMessage.BoxContainer}>
                <ImageBW
                  source={
                    redeem.currency === COINS
                      ? require(asset`PromoImage_Coins.png`)
                      : require(asset`PromoImage_Loyalty.png`)
                  }
                  ibw={
                    redeem.currency === COINS
                      ? require(asset`PromoImage_Coins.ibw`)
                      : require(asset`PromoImage_Loyalty.ibw`)
                  }
                  style={{
                    width: '100%',
                    height: 'fit-content',
                  }}
                  resizeMode="contain"
                />
                <div
                  style={
                    redeem.currency === COINS
                      ? themeContext.BonusLinkMessage.CoinsBonusContainer
                      : themeContext.BonusLinkMessage.LPBonusContainer
                  }
                >
                  <p style={{ width: '100%', textAlign: 'center', marginBottom: '-5px' }}>
                    {getText(TEXT_KEY.BONUS_LINK_MESSAGE_DESCRIPTION)}
                  </p>
                  <div style={themeContext.BonusLinkMessage.BonusInfoContainer}>
                    <ImageBW
                      source={
                        redeem.currency === COINS
                          ? require(asset`Coin_Icon@3x.png`)
                          : require(asset`loyalty-icon@3x.png`)
                      }
                      ibw={redeem.currency === COINS ? require(asset`Coin_Icon.ibw`) : require(asset`loyalty-icon.ibw`)}
                      style={themeContext.BonusLinkMessage.CoinImage}
                      alt="Close"
                    />
                    <h1>{format(redeem.amount)}</h1>
                  </div>
                  <Button
                    containerStyle={{ position: 'relative', cursor: 'pointer', width: '100%' }}
                    imageSource={require(asset`RoundedRect_Primary@3x.png`)}
                    label={getText(TEXT_KEY.BONUS_LINK_MESSAGE_BUTTON)}
                    textStyle={themeContext.BonusLinkMessage.BonusLinkButton}
                    onClick={this.props.dismissModal}
                  />
                </div>
              </div>
            );
          }
        }
      />
    );
  }
}

BonusLinkMessage.hideDefaultBackground = true;
BonusLinkMessage.hideCloseButton = true;
BonusLinkMessage.contextType = ThemeContext;

