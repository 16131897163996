import React, { useState } from 'react';
import styled from 'styled-components';
import { TEXT_KEY, getText } from 'shared/utils/localization';
import Button from '../../../../../components/Button/Button';

const InviteScreen = props => {
  const { friendsInviteFeatureState, friendsInviteAssets } = props;

  const [isLinkCopied, setIsLinkCopied] = useState(false);
  const [showInviteLink, setShowInviteLink] = useState(false);

  const copyInviteLink = () => {
    navigator.clipboard.writeText(friendsInviteFeatureState?.inviteLink?.inviteLinkUrl);
    setIsLinkCopied(true);
  };

  return (
    <Container {...props}>
      <TitleImage src={friendsInviteAssets?.SOCIAL_FRIENDS_MORE_COINS_TITLE} />
      <Body>
        <Description isInviteLink={showInviteLink}>
          {!showInviteLink
            ? getText(TEXT_KEY.SOCIAL_INVITE_SCREEN_DESCRIPTION)
            : getText(TEXT_KEY.SOCIAL_INVITE_SCREEN_DESCRIPTION_WEB)}
        </Description>
        {!showInviteLink ? (
          <Button
            onClick={() => setShowInviteLink(true)}
            imageSource={require(asset`RoundedRect_Primary@3x.png`)}
            label={getText(TEXT_KEY.SOCIAL_INVITE_SCREEN_BUTTON)}
            containerStyle={ButtonStyle}
          />
        ) : (
          <InviteLink onClick={copyInviteLink}>{friendsInviteFeatureState?.inviteLink?.inviteLinkUrl}</InviteLink>
        )}
        {isLinkCopied && <CopiedLink>{getText(TEXT_KEY.SOCIAL_INVITE_SCREEN_LINK_COPIED)}</CopiedLink>}
        <Footer>{getText(TEXT_KEY.SOCIAL_INVITE_SCREEN_FOOTER)}</Footer>
      </Body>
      <BottomImage src={friendsInviteAssets?.SOCIAL_FRIENDS_CHARACTERS_IMAGE} />
    </Container>
  );
};

export default InviteScreen;

const Container = styled.div`
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  opacity: ${({ isGuestUser, hasActiveState }) => (isGuestUser || !hasActiveState ? 0.1 : 1)};
  display: ${({ limitReached, friendsInviteAssets }) => (limitReached || !friendsInviteAssets ? 'none' : 'flex')};
`;

const TitleImage = styled.img`
  width: 100%;
  height: 10rem;
  object-fit: contain;
  position: relative;
  top: 1rem;
`;

const Body = styled.div`
  width: 100%;
  height: 55%;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-direction: column;
  padding-left: 1.8rem;
  padding-right: 1.8rem;
`;

const Description = styled.p`
  font-size: 1.2rem;
  color: white;
  text-align: center;
  font-weight: 700;
  width: ${({ isInviteLink }) => (isInviteLink ? '100%' : '45%')};
`;

const InviteLink = styled.p`
  font-size: 1.2rem;
  color: white;
  text-align: center;
  font-weight: 700;
  cursor: pointer;
`;

const CopiedLink = styled.p`
  font-size: 0.9rem;
  color: white;
  text-align: center;
  position: absolute;
  top: 11rem;
`;

const Footer = styled.p`
  font-size: 0.8rem;
  color: white;
  text-align: center;
`;

const BottomImage = styled.img`
  width: 30rem;
  height: 10rem;
  object-fit: contain;
  position: relative;
  top: 0.5rem;
`;

const ButtonStyle = {
  marginTop: '1rem',
  marginBottom: '2rem',
};

