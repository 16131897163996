import React from 'react';
import { Scrollbars } from 'react-custom-scrollbars';
import useCustomScrollBar from './hooks/useCustomScrollBar';
import useDragToScroll from './hooks/useDragToScroll';
import {
  ArrowButton,
  CustomRenderView,
  HorizontalScrollThumb,
  HorizontalScrollTrack,
  VerticalScrollThumb,
  VerticalScrollTrack,
} from './styledComponents';

export default function CustomScrollBar({
  customHeight = '460px',
  customWidth = '100%',
  children,
  showByDefault,
  timeout = 0,
  customStyle = {},
  containerStyle = {},
  customHorizontalTrackStyle = {},
  customHorizontalThumbStyle = {},
  customArrowStyle = {},
  customScrollBarXHeight = 10,
  arrowClickScrollAmount = 320,
  showHorizontal = false,
  showVertical = true,
  showHorizontalArrows = false,
  isDraggableX = false,
  isDraggableY = false,
  scrollToPosition = null,
  verticalScrollThumbStyle = {},
  verticalScrollTrackStyle = {},
}) {
  const { onScroll, handleArrowScrollClick, scrollBoxRef, scrollBarWidth, scrollBarXHeight } = useCustomScrollBar({
    showByDefault,
    timeout,
    showVertical,
    showHorizontal,
    customScrollBarXHeight,
    arrowClickScrollAmount,
    scrollToPosition,
  });
  useDragToScroll({ scrollBoxRef, isDraggableX, isDraggableY });

  const handleLeftClick = () => handleArrowScrollClick('left');
  const handleRightClick = () => handleArrowScrollClick();

  return (
    <>
      <Scrollbars
        ref={scrollBoxRef}
        style={{ height: `calc(100vh - ${customHeight})`, marginRight: '-2px', width: customWidth, ...containerStyle }}
        onScroll={onScroll}
        renderThumbVertical={props => (
          <VerticalScrollThumb
            {...props}
            width={scrollBarWidth}
            display={showVertical && scrollBarWidth > 0}
            customStyle={verticalScrollThumbStyle}
          />
        )}
        renderTrackVertical={props => (
          <VerticalScrollTrack
            {...props}
            width={scrollBarWidth}
            display={showVertical && scrollBarWidth > 0}
            customStyle={verticalScrollTrackStyle}
          />
        )}
        renderView={props => <CustomRenderView {...props} customStyle={customStyle} />}
        renderThumbHorizontal={props => (
          <HorizontalScrollThumb
            {...props}
            height={scrollBarXHeight}
            display={showHorizontal}
            customThumbStyle={customHorizontalThumbStyle}
          />
        )}
        renderTrackHorizontal={props => (
          <HorizontalScrollTrack
            {...props}
            height={scrollBarXHeight}
            display={showHorizontal}
            customTrackStyle={customHorizontalTrackStyle}
          />
        )}
      >
        {children}
      </Scrollbars>
      {showHorizontal && showHorizontalArrows && (
        <div style={{ position: 'relative', width: customWidth }}>
          <LeftArrow onClick={handleLeftClick} customArrowStyle={customArrowStyle} />
          <RightArrow onClick={handleRightClick} customArrowStyle={customArrowStyle} />
        </div>
      )}
    </>
  );
}

const LeftArrow = ({ onClick, customArrowStyle }) => (
  <ArrowButton src={require(asset`Back_Arrow@3x.png`)} onClick={onClick} customArrowStyle={customArrowStyle} />
);

const RightArrow = ({ onClick, customArrowStyle }) => (
  <ArrowButton
    isRightArrow
    src={require(asset`Back_Arrow@3x.png`)}
    onClick={onClick}
    customArrowStyle={customArrowStyle}
  />
);

