import React from 'react';
import Animated from 'animated/lib/targets/react-dom';
import Easing from 'animated/lib/Easing';
import useGetDailyBonusData from 'shared/screens/DailySpin/hooks/useGetDailyBonusData';
import useAnimationHandler from 'shared/screens/DailySpin/hooks/useAnimationHandler';
import useInfoPopupHandler from 'shared/screens/DailySpin/hooks/useInfoPopupHandler';
import { animationStyleGenerator } from './utils';
import Wheel from './components/Wheel';
import StreakColumn from './components/StreakColumn/StreakColumn';
import BottomBar from './components/BottomBar/BottomBar';
import InfoPopUp from './components/InfoPopUp';
import { HiddenContentWrapper, PageContainer } from './components/styledComponents';

export default function DailySpin(props) {
  const { showInfoModal, toggleInfoModal } = useInfoPopupHandler();

  const {
    claimDailyBonus,
    step,
    changeStep,
    bonusInfo,
    spinnerItems,
    totalCoinsAfterTierBonus,
    showTotalCoins,
    showTotalLoyalty,
    dailyBonus,
    wheelBonus,
    totalLoyalty,
    currentTierBonus,
    isPhysicallyVerified,
    linkedAccount,
    loginStreakList,
    loginStreakFinalDayNum,
    loginStreakFinal,
    isLoading,
    dailyWheelAssets,
  } = useGetDailyBonusData(props);

  const {
    nextStepHandler,
    animateWheelIn,
    bottomAnim,
    wheelInAnim,
    wheelPostSpinAnim,
    fastForwardButtonAnim,
    streakAnim,
    spinAnim,
  } = useAnimationHandler({
    claimDailyBonus,
    Easing,
    Animated,
    step,
    changeStep,
    bonusInfo,
  });

  const {
    fastForwardButtonAnimDeg,
    spinAnimDeg,
    wheelInAnimDeg,
    wheelPostSpinAnimDeg,
    streakAnimDeg,
    bottomAnimDeg,
    bottomBarAnimDeg,
  } = animationStyleGenerator(spinAnim, fastForwardButtonAnim, wheelInAnim, wheelPostSpinAnim, streakAnim, bottomAnim);

  return (
    dailyWheelAssets && (
      <PageContainer background={dailyWheelAssets.DAILY_SPIN_BACKGROUND}>
        <InfoPopUp
          dailyWheelAssets={dailyWheelAssets}
          showInfoModal={showInfoModal}
          toggleInfoModal={toggleInfoModal}
        />
        <Wheel
          step={step}
          wheelInAnimDeg={wheelInAnimDeg}
          wheelPostSpinAnimDeg={wheelPostSpinAnimDeg}
          spinnerItems={spinnerItems}
          spinAnimDeg={spinAnimDeg}
          fastForwardButtonAnimDeg={fastForwardButtonAnimDeg}
          animateWheelIn={animateWheelIn}
          nextStepHandler={nextStepHandler}
        />

        <HiddenContentWrapper hide={step < 2}>
          <StreakColumn
            streakAnimDeg={streakAnimDeg}
            bottomAnimDeg={bottomAnimDeg}
            loginStreakFinal={loginStreakFinal}
            loginStreakList={loginStreakList}
            bonusInfo={bonusInfo}
            loginStreakFinalDayNum={loginStreakFinalDayNum}
            toggleInfoModal={toggleInfoModal}
          />
          <BottomBar
            isLoading={isLoading}
            bottomBarAnimDeg={bottomBarAnimDeg}
            wheelBonus={wheelBonus}
            dailyBonus={dailyBonus}
            isPhysicallyVerified={isPhysicallyVerified}
            linkedAccount={linkedAccount}
            currentTierBonus={currentTierBonus}
            showTotalCoins={showTotalCoins}
            totalCoinsAfterTierBonus={totalCoinsAfterTierBonus}
            totalLoyalty={totalLoyalty}
            nextStepHandler={nextStepHandler}
            showTotalLoyalty={showTotalLoyalty}
            step={step}
          />
        </HiddenContentWrapper>
      </PageContainer>
    )
  );
}

DailySpin.hideDefaultBackground = true;
DailySpin.hideCloseButton = true;
DailySpin.hideOverlay = true;
DailySpin.AsModalProps = {
  style: {
    padding: 0,
  },
  dialogClassName: 'gtp-modal-dailybonus',
};

