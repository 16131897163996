import { createApi } from '@reduxjs/toolkit/query/react';
import { axiosBaseQuery } from '../../api/services/rtkQuery/axiosBaseQuery';
import { getFriendsInviteQuery } from './queries';
export var friendsInviteApi = createApi({
  reducerPath: 'friendsInviteQuery',
  baseQuery: axiosBaseQuery(),
  keepUnusedDataFor: 10,
  tagTypes: ['FriendsInvite'],
  endpoints: function endpoints(builder) {
    return {
      getFriendsInvite: builder.query(getFriendsInviteQuery)
    };
  }
});
var useGetFriendsInviteQuery = friendsInviteApi.useGetFriendsInviteQuery;
export { useGetFriendsInviteQuery };
var getFriendsInvite = friendsInviteApi.endpoints.getFriendsInvite,
    friendsInviteReducerPath = friendsInviteApi.reducerPath,
    friendsInviteQueryReducer = friendsInviteApi.reducer,
    friendsInviteMiddleware = friendsInviteApi.middleware;
export { friendsInviteReducerPath, friendsInviteQueryReducer, friendsInviteMiddleware, getFriendsInvite };