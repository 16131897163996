import React, { useContext, useRef } from 'react';
import debounce from 'lodash/debounce';
import useMissionsProgress from 'shared/api/MissionsInterface/hooks/useMissionsProgress';
import ThemeContext from 'shared/context/ThemeContext';
import useMissionsTooltips from 'shared/hooks/useMissionsTooltips';
import { missionFinished } from 'shared/utils/missionProgress/tooltipsDetail';
import { counterTypes, normalizeTimestamp } from 'shared/utils/TimestampManager';
import TimeRemainingText from '../TimeRemainingText';
import {
  Container,
  TimeBar,
  TimeText,
  ProgressBarContainerStyle,
  CollectBarContainerStyle,
  ProgressBarStyle,
  BarLabelStyle,
} from './styledComponent';
import Bar from '../Bar/Bar';
import { TEXT_KEY, getText } from 'shared/utils/localization';
import useFeatureFlags from 'shared/hooks/useFeatureFlags';
import { FE_MISSIONS } from 'shared/utils/featureFlags/constants';

const MissionsProgress = props => {
  const { MissionsProgress } = useContext(ThemeContext);
  const mouseOver = useRef(false);
  const missionFinishedTooltipDetail = missionFinished();
  const { missionsAssets, isMissionsScreen, showLinkLoyaltyCardSetting } = props;

  const { getFeatureConfig } = useFeatureFlags();
  const { skinning } = getFeatureConfig(FE_MISSIONS);

  const { clientProgress, serverProgress } = useMissionsProgress(props);
  const { onMissionProgressPressHandler } = useMissionsTooltips({
    ...props,
    missionFinishedTooltipDetail,
  });

  const onMouseEnterHandler = () => {
    if (serverProgress < 100 && !mouseOver.current) {
      mouseOver.current = true;
      onMissionProgressPressHandler();
      setTimeout(() => (mouseOver.current = false), 4500);
    }
  };

  const isCollectStatus = serverProgress === 100 && !isMissionsScreen;

  return (
    <Container
      onClick={
        serverProgress < 100
          ? null
          : debounce(() => {
              onMissionProgressPressHandler();
            }, 200)
      }
      onMouseEnter={onMouseEnterHandler}
    >
      <Bar
        barContainerStyle={{
          background: 'rgb(33,30,28)',
          ...(!isCollectStatus
            ? {
                ...ProgressBarContainerStyle,
                ...(skinning && {
                  background: `linear-gradient(180deg, ${skinning.barBackgroundColors[0]} 0%, ${skinning.barBackgroundColors[1]} 100%)`,
                }),
              }
            : CollectBarContainerStyle),
        }}
        progressStyle={{
          ...ProgressBarStyle,
          ...(skinning && {
            background: `linear-gradient(180deg, ${skinning.progressBarColors[0]} 0%, ${skinning.progressBarColors[1]} 100%)`,
          }),
        }}
        barIcon={missionsAssets.TOP_BAR_ICON}
        iconStyle={showLinkLoyaltyCardSetting ? MissionsProgress.MissionIcon : MissionsProgress.MissionIconWithoutCard}
        progress={!isCollectStatus ? Math.floor(clientProgress) : null}
        percentageStyle={{
          ...(showLinkLoyaltyCardSetting ? MissionsProgress.TextProgress : MissionsProgress.TextProgressWithoutCard),
          ...MissionsProgress.TextProgress,
          left: `calc(50% - ${serverProgress < 100 ? 1 : 1.5}rem)`,
        }}
        label={isCollectStatus && getText(TEXT_KEY.COLLECT_BUTTON)}
        labelStyle={BarLabelStyle}
        shineEffect
        showPercentage={!isCollectStatus}
        showProgress={!isCollectStatus}
      />
      <TimeBar
        theme={showLinkLoyaltyCardSetting ? MissionsProgress.TimeBar : MissionsProgress.TimeBarWithoutCard}
        src={missionsAssets.TOP_BAR_TIMER}
      />
      <TimeRemainingText
        counterType={counterTypes.MISSION_CARD}
        style={{
          ...TimeText,
          ...(showLinkLoyaltyCardSetting
            ? { ...MissionsProgress.TimeRemainingText }
            : { ...MissionsProgress.TimeRemainingTextWithoutCard }),
        }}
        until={normalizeTimestamp(props.currentMission.endTimestamp)}
        inNavBar
      />
    </Container>
  );
};

export default MissionsProgress;

