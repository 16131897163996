import React from 'react';
import MessageItem from './MessageItem';
import CustomScrollBar from '../../../../../components/CustomScrollBar';
import theme from 'shared/assets/style/theme';
import generateRender from 'shared/utils/generateRender';

const MessageList = props => {
  const { messages, navigation, setActiveMessage } = props;
  return (
    <CustomScrollBar
      customeStyle={{ marginRight: '-5px !important' }}
      containerStyle={{
        marginTop: '1.5rem',
        width: '95%',
      }}
      customHeight="30vh"
      verticalScrollThumbStyle={{
        backgroundColor: scrollBarThumbColor,
      }}
      verticalScrollTrackStyle={{
        backgroundColor: scrollBarTrackColor,
        top: '1.5rem',
        right: '1rem',
      }}
    >
      {messages?.map(message => (
        <MessageItem
          key={message.message_id}
          message={message}
          messageDetailsHandle={setActiveMessage}
          navigation={navigation}
          {...props}
        />
      ))}
    </CustomScrollBar>
  );
};

export default MessageList;

const scrollBarTrackColor = generateRender({
  default: theme.palette.secondary[27],
  sga: theme.palette.secondary[41],
  ti: 'rgba(11, 39, 53, 1)',
  admiral: 'rgba(132, 129, 165, 1)',
  admes: 'rgba(132, 129, 165, 1)',
  star: theme.palette.primary[0],
});

const scrollBarThumbColor = generateRender({
  default: theme.palette.primary[34],
  sga: 'rgba(241, 133, 76, 1)',
  ti: theme.palette.primary[31],
  star: theme.palette.primary[25],
});

