import _defineProperty from "/var/jenkins/workspace/Web Clients and Images - Production/Code/WEB CLIENT - GTP 2.0 Deploy/platforms/web/node_modules/babel-preset-react-app/node_modules/@babel/runtime/helpers/esm/defineProperty";

var _generateImageSource;

import { ACTION_COMMANDS_BUTTON_TYPE } from '../../../api/ActionCommandsInterface/constants';
import { EVENT_EMITTER_TYPE } from '../../../constants';
import Emitter from '../../../services/EmitterService';
import QueryParamProxy from '../../../utils/queryParamProxy';
import routeParamConstants from '../../../utils/routeParamConstants';
var generateImageSource = (_generateImageSource = {}, _defineProperty(_generateImageSource, ACTION_COMMANDS_BUTTON_TYPE.ACCEPT, require("../../../assets/".concat("millelacs", "/images/RoundedRect_Primary.png"))), _defineProperty(_generateImageSource, "default", null), _generateImageSource);

var generateActionHandler = function generateActionHandler(modal, onClickAction) {
  Emitter.emit(EVENT_EMITTER_TYPE.ACTION_COMMANDS, {
    actionType: onClickAction.actionType,
    data: {
      modal: modal,
      onClickAction: onClickAction
    }
  });
  QueryParamProxy.setQueryParams(_defineProperty({}, routeParamConstants.PARAM_MODAL, undefined));
};

export var generateButtonsRendering = function generateButtonsRendering(modal) {
  var buttons = modal.buttons;
  var buttonsRendering = [];
  buttons && Object.values(buttons).forEach(function (button) {
    var buttonType = button.buttonType,
        label = button.label,
        onClickAction = button.onClickAction;
    var buttonRendering = {
      label: label,
      imageSource: generateImageSource[buttonType] || generateImageSource['default'],
      action: function action() {
        return generateActionHandler(modal, onClickAction);
      }
    };
    buttonsRendering.push(buttonRendering);
  });
  return buttonsRendering;
};