import _defineProperty from "/var/jenkins/workspace/Web Clients and Images - Production/Code/WEB CLIENT - GTP 2.0 Deploy/platforms/web/node_modules/babel-preset-react-app/node_modules/@babel/runtime/helpers/esm/defineProperty";

function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); enumerableOnly && (symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; })), keys.push.apply(keys, symbols); } return keys; }

function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = null != arguments[i] ? arguments[i] : {}; i % 2 ? ownKeys(Object(source), !0).forEach(function (key) { _defineProperty(target, key, source[key]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)) : ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } return target; }

import { SET_CASINO_ID_STATUS, SET_CASINO_ID, SET_KYC_STATUS, SET_PROPERTY_NAME, SET_PROPERTY_ID, SET_SOUND_SETTINGS, IS_ACCOUNT_LINKED, SET_CURRENT_MEMBER_LEVEL, SET_IS_DEV_USER, SET_AFF_ID, SET_RATING_POPUP_TIME, SET_AD_BLOCKER_POPUP_TIME, SET_GAMES_OPENED_AMOUNT, SET_APPSFLYER_GAMES_OPENED_AMOUNT_CONFIG, SET_PLAYER_ID, CLEAR_TOKEN, SET_INVITE_LINK } from '../actions/types';
var initialState = {
  playerId: null,
  casinoIdStatus: '',
  casinoId: '',
  isAccountLinked: false,
  memberLevel: '',
  propertyId: '',
  isDevUser: false,
  aff_id: null,
  KYCInfo: {},
  lastTimeRated: null,
  lastTimeAdBlockerSeen: null,
  gamesOpenedAmount: 0,
  inviteLink: null
};
export var playerInfoReducer = function playerInfoReducer() {
  var state = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : initialState;
  var action = arguments.length > 1 ? arguments[1] : undefined;

  switch (action.type) {
    case SET_PLAYER_ID:
      return _objectSpread(_objectSpread({}, state), {}, {
        playerId: action.payload
      });

    case CLEAR_TOKEN:
      return _objectSpread(_objectSpread({}, state), {}, {
        playerId: null
      });

    case SET_CASINO_ID_STATUS:
      return _objectSpread(_objectSpread({}, state), {}, {
        casinoIdStatus: action.payload
      });

    case SET_CASINO_ID:
      return _objectSpread(_objectSpread({}, state), {}, {
        casinoId: action.payload
      });

    case SET_KYC_STATUS:
      return _objectSpread(_objectSpread({}, state), {}, {
        isKYCDone: action.payload.isKYCDone,
        KYCResult: action.payload.KYCResult,
        KYCInfo: action.payload.KYCInfo
      });

    case SET_PROPERTY_NAME:
      return _objectSpread(_objectSpread({}, state), {}, {
        propertyName: action.payload
      });

    case SET_PROPERTY_ID:
      return _objectSpread(_objectSpread({}, state), {}, {
        propertyId: action.payload
      });

    case SET_SOUND_SETTINGS:
      return _objectSpread(_objectSpread({}, state), {}, {
        soundEffects: action.payload.soundEffects,
        music: action.payload.music
      });

    case IS_ACCOUNT_LINKED:
      return _objectSpread(_objectSpread({}, state), {}, {
        isAccountLinked: action.payload
      });

    case SET_CURRENT_MEMBER_LEVEL:
      return _objectSpread(_objectSpread({}, state), {}, {
        memberLevel: action.payload
      });

    case SET_IS_DEV_USER:
      return _objectSpread(_objectSpread({}, state), {}, {
        isDevUser: action.payload
      });

    case SET_AFF_ID:
      return _objectSpread(_objectSpread({}, state), {}, {
        aff_id: action.payload
      });

    case SET_RATING_POPUP_TIME:
      return _objectSpread(_objectSpread({}, state), {}, {
        lastTimeRated: action.payload
      });

    case SET_AD_BLOCKER_POPUP_TIME:
      return _objectSpread(_objectSpread({}, state), {}, {
        lastTimeAdBlockerSeen: action.payload
      });

    case SET_GAMES_OPENED_AMOUNT:
      return _objectSpread(_objectSpread({}, state), {}, {
        gamesOpenedAmount: action.payload
      });

    case SET_APPSFLYER_GAMES_OPENED_AMOUNT_CONFIG:
      return _objectSpread(_objectSpread({}, state), {}, {
        appsflyerGamesOpenedAmountConfig: action.payload
      });

    case SET_INVITE_LINK:
      return _objectSpread(_objectSpread({}, state), {}, {
        inviteLink: action.payload
      });

    default:
      return state;
  }
};
export default playerInfoReducer;