import React from 'react';
import Animated from 'animated/lib/targets/react-dom';
import ImageBW from '../ImageBoundsWrapper';
import TooltipContainer from './components/TooltipContainer';

export default function DefaultTooltip({ props }) {
  const {
    displayText,
    iconType,
    children,
    arrowStyle,
    source,
    actionHandler,
    ibw,
    animationClassName,
    showArrow = true,
    style,
    bottom,
    displayTextStyle,
    containerStyle = {},
  } = props;

  return (
    <Animated.div
      className={`Tooltip-Container ${animationClassName ? animationClassName : 'AnimationFadeIn'}`}
      style={{
        ...style,
      }}
    >
      <TooltipContainer
        showArrow={showArrow}
        arrowStyle={arrowStyle}
        actionHandler={actionHandler}
        bottomArrow={bottom}
        containerStyle={containerStyle}
      >
        {children ? (
          children
        ) : (
          <div className="Tooltip-Label">
            {ibw && <ImageBW source={source} ibw={ibw} style={{}} className={`Icon ${iconType}`} />}
            <div className="Label" style={displayTextStyle}>
              {displayText}
            </div>
          </div>
        )}
      </TooltipContainer>
    </Animated.div>
  );
}

