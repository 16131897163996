import MessageItem from './MessageItem';
import { connect } from '../../node_modules/react-redux';
import { setPurchaseType as _setPurchaseType } from '../../state/actions/prePurchase';

var mapStateToProps = function mapStateToProps(state) {
  return {
    unlimitedVipAccess: state.consumables.unlimitedVipAccess
  };
};

var mapDispatchToProps = function mapDispatchToProps(dispatch) {
  return {
    setPurchaseType: function setPurchaseType(type) {
      dispatch(_setPurchaseType(type));
    }
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(MessageItem);