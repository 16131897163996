import _defineProperty from "/var/jenkins/workspace/Web Clients and Images - Production/Code/WEB CLIENT - GTP 2.0 Deploy/platforms/web/node_modules/babel-preset-react-app/node_modules/@babel/runtime/helpers/esm/defineProperty";

function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); enumerableOnly && (symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; })), keys.push.apply(keys, symbols); } return keys; }

function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = null != arguments[i] ? arguments[i] : {}; i % 2 ? ownKeys(Object(source), !0).forEach(function (key) { _defineProperty(target, key, source[key]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)) : ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } return target; }

import Shop from './Shop';
import { connect } from '../../node_modules/react-redux';
import { setNavState as _setNavState } from '../../state/actions/nav_state';
import { setShopListCoins as _setShopListCoins, setShopListVip as _setShopListVip, setLastPromoCode as _setLastPromoCode, setPaymentProvider as _setPaymentProvider } from '../../state/actions/shopList';
import { setPurchaseType as _setPurchaseType, isOutOfCoinsPurchase as _isOutOfCoinsPurchase, isLTOPurchase as _isLTOPurchase, setPurchaseProfile as _setPurchaseProfile } from '../../state/actions/prePurchase';
import { initiatePurchase as _initiatePurchase } from '../../state/actions/purchase';
import { activatePromocode as _activatePromocode, dismissInvalidPromocode as _dismissInvalidPromocode } from '../../state/actions/pendingMessage';
import { assignTransationUUID as _assignTransationUUID } from '../../state/actions/purchase';
import { setAuthCallbackPurchaseData as _setAuthCallbackPurchaseData } from '../../state/actions/callbackIntent';

var mapStateToProps = function mapStateToProps(state) {
  return _objectSpread({
    // Add state from Shop
    navState: state.navState.navState,
    activeConsumables: state.consumables.active,
    promoLink: state.pendingMessages.find(function (pendingMessage) {
      return pendingMessage.type === 'PROMO_LINK';
    }),
    lastPromoCode: state.shopList.lastUsedPromoCode,
    paymentProvider: state.shopList.paymentProvider,
    coins: state.coin.value,
    assetsUrls: state.app.assetsUrls,
    unlimitedVipAccess: state.consumables.unlimitedVipAccess,
    memberLevel: state.playerInfo.memberLevel
  }, state.playerInfo);
};

var mapDispatchToProps = function mapDispatchToProps(dispatch) {
  return {
    // Add action from Shop
    setNavState: function setNavState(state) {
      dispatch(_setNavState(state));
    },
    setShopListCoins: function setShopListCoins(coins) {
      dispatch(_setShopListCoins(coins));
    },
    setShopListVip: function setShopListVip(vip) {
      dispatch(_setShopListVip(vip));
    },
    setLastPromoCode: function setLastPromoCode(code) {
      dispatch(_setLastPromoCode(code));
    },
    setPaymentProvider: function setPaymentProvider(provider) {
      dispatch(_setPaymentProvider(provider));
    },
    setPurchaseType: function setPurchaseType(type) {
      dispatch(_setPurchaseType(type));
    },
    isOutOfCoinsPurchase: function isOutOfCoinsPurchase(value) {
      dispatch(_isOutOfCoinsPurchase(value));
    },
    isLTOPurchase: function isLTOPurchase(value) {
      dispatch(_isLTOPurchase(value));
    },
    activatePromocode: function activatePromocode() {
      dispatch(_activatePromocode());
    },
    dismissInvalidPromocode: function dismissInvalidPromocode() {
      dispatch(_dismissInvalidPromocode());
    },
    assignTransationUUID: function assignTransationUUID(value) {
      dispatch(_assignTransationUUID(value));
    },
    initiatePurchase: function initiatePurchase() {
      dispatch(_initiatePurchase());
    },
    setAuthCallbackPurchaseData: function setAuthCallbackPurchaseData(purchaseData) {
      dispatch(_setAuthCallbackPurchaseData(purchaseData));
    },
    setPurchaseProfile: function setPurchaseProfile(profileData) {
      dispatch(_setPurchaseProfile(profileData));
    }
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Shop);