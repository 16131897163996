import React from 'react';
import { TEXT_KEY, getText } from 'shared/utils/localization';
import styled from 'styled-components';
import LinkAccountButtons from '../../../../Settings/Account/components/LinkAccount/DefaultLinkAccount';

const NotificationScreen = props => {
  const { hasComingSoonState } = props;

  return (
    <Container {...props}>
      <NotificationError
        src={
          hasComingSoonState
            ? require(asset`images/social/coming-soon.png`)
            : require(asset`images/social/under-maintenance.png`)
        }
        {...props}
      />
      <Wrapper {...props}>
        <Title>{getText(TEXT_KEY.SOCIAL_GUEST_USER_TITLE)}</Title>
        <LinkAccountButtons
          rowWrapperStyles={LinkButtonContainer}
          isFriendsInvite
          facebookLinkButtonStyles={LinkButtonStyles}
          memberLinkButtonStyles={LinkButtonStyles}
        />
      </Wrapper>
    </Container>
  );
};

export default NotificationScreen;

const Container = styled.div`
  height: 100%;
  width: 99%;
  align-items: center;
  justify-content: center;
  position: absolute;
  display: ${({ isGuestUser, hasActiveState, friendsInviteAssets }) =>
    isGuestUser || !hasActiveState || !friendsInviteAssets ? 'flex' : 'none'};
`;

const NotificationError = styled.img`
  width: ${({ hasComingSoonState }) => (hasComingSoonState ? 10 : 15)}rem;
  height: ${({ hasComingSoonState }) => (hasComingSoonState ? 14 : 20)}rem;
  display: ${({ hasActiveState, friendsInviteAssets }) => (!hasActiveState || !friendsInviteAssets ? 'flex' : 'none')};
  object-fit: contain;
`;

const Wrapper = styled.div`
  width: 100%;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  display: ${({ isGuestUser, hasActiveState }) => (isGuestUser && hasActiveState ? 'flex' : 'none')};
`;

const Title = styled.p`
  font-size: 1.3rem;
  color: white;
  font-weight: 700;
  font-style: italic;
`;

const LinkButtonContainer = {
  width: '65%',
  gap: 1,
};

const LinkButtonStyles = {
  objectFit: 'contain',
};

