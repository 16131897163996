import { getText, TEXT_KEY } from 'shared/utils/localization';
import { messagePinkButtonStroke, messageOrangeButtonStroke } from 'shared/components/MessageItem/utils';
import generateRender from 'shared/utils/generateRender';
import { messageTypes } from 'shared/utils/messageTypes';

const buttonTypes = () => ({
  COLLECT: {
    background: require(asset`Orange_Button@3x.png`),
    text: getText(TEXT_KEY.COLLECT_BUTTON),
    textStroke: messageOrangeButtonStroke,
  },
  REDEEM: {
    background: require(asset`Pink_Button@3x.png`),
    text: getText(TEXT_KEY.REDEEM_BUTTON),
    textStroke: messagePinkButtonStroke,
  },
  PLAY: {
    background: require(asset`Pink_Button@3x.png`),
    text: getText(TEXT_KEY.PLAY_BUTTON),
    textStroke: messagePinkButtonStroke,
  },
  BUY: {
    background: require(asset`Pink_Button@3x.png`),
    text: getText(TEXT_KEY.BUY_BUTTON),
    textStroke: messagePinkButtonStroke,
  },
  SNEAK_PEEK: {
    background: require(asset`Pink_Button@3x.png`),
    text: getText(TEXT_KEY.SNEAK_PEEK_BUTTON),
    textStroke: messagePinkButtonStroke,
  },
});

export const messagesTypes = () => ({
  COINS: {
    background: require(asset`Coins_Default@3x.png`),
    icon: require(asset`Coins_Mail_Icon@3x.png`),
    image: require(asset`images/Top_Bar_Assets/Coin_Icon@3x.png`),
    imageIBW: require(asset`Coin_Icon_Inbox.ibw`),
    button: buttonTypes().COLLECT,
    type: messageTypes.COINS,
  },
  LOYALTY: {
    background: require(asset`Message_Inbox_Loyalty_Promo@3x.png`),
    icon: require(asset`Loyalty_Mail_Icon@3x.png`),
    image: require(asset`loyalty-icon@3x.png`),
    imageIBW: require(asset`loyalty-icon-inbox.ibw`),
    button: buttonTypes().COLLECT,
    type: messageTypes.LOYALTY,
  },
  SNEAK_PEEK: {
    background: require(asset`images/SneakPeek/sneak_peek_background@3x.png`),
    icon: require(asset`images/SneakPeek/icon_sneak_peek@3x.png`),
    button: buttonTypes().SNEAK_PEEK,
    type: messageTypes.SNEAK_PEEK,
  },
  VIP_PASS: {
    background: require(asset`images/VIP_Message_Assets/VIP_Message_Background@3x.png`),
    icon: require(asset`images/VIP_Message_Assets/VIP_Message_Icon@3x.png`),
    button: buttonTypes().REDEEM,
    type: messageTypes.VIP_PASS,
  },
  PROMO: {
    background: '',
    icon: require(asset`Coins_Mail_Icon@3x.png`),
    type: messageTypes.PROMO,
  },
  OFFER: {
    background: require(asset`Coins_Default@3x.png`),
    icon: require(asset`Coins_Mail_Icon@3x.png`),
    button: buttonTypes().BUY,
    type: messageTypes.OFFER,
  },
  GAME: {
    background: '',
    icon: require(asset`Coins_Mail_Icon@3x.png`),
    button: buttonTypes().PLAY,
    type: messageTypes.GAME,
  },
});

export const closeButton = generateRender({
  admiral: require(asset`CloseButton@3x.png`),
  default: require(asset`Close_Button@3x.png`),
});

