import styled from 'styled-components';

const MilestoneRow = styled.div`
  position: absolute;
  top: ${({ hero }) => (hero ? '9.5%' : '19%')};
  width: 142%;
  height: ${({ hero }) => (hero ? 210 : 170)}px;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  z-index: 3;

  @media only screen and (max-height: 1050px) {
    top: ${({ hero }) => (hero ? '10.5%' : '19.2%')};
  }

  @media only screen and (max-height: 850px) {
    top: ${({ hero }) => (hero ? '11.5%' : '20%')};
  }

  @media only screen and (max-height: 900px) {
    top: ${({ hero }) => (hero ? '10.3%' : '19%')};
  }

  @media only screen and (max-height: 800px) {
    top: ${({ hero }) => (hero ? '9.5%' : '17.3%')};
  }

  @media only screen and (min-width: 1900px) and (min-height: 1050px) {
    height: ${({ hero }) => (hero ? 250 : 190)}px;
    top: ${({ hero }) => (hero ? '9.5%' : '19%')};
  }

  @media only screen and (min-width: 2200px) and (min-height: 1200px) {
    height: ${({ hero }) => (hero ? 250 : 190)}px;
    top: ${({ hero }) => (hero ? '10.5%' : '19.5%')};
  }

  @media only screen and (min-width: 2400px) and (min-height: 1400px) {
    height: ${({ hero }) => (hero ? 300 : 190)}px;
    top: ${({ hero }) => (hero ? '10.5%' : '21%')};
  }
`;

export default MilestoneRow;

