import React, { useState } from 'react';
import useMessageDetails from 'shared/screens/Social/hooks/useMessageDetail';
import styled from 'styled-components';
import Button from '../../../../../components/Button/Button';
import { isEmpty } from 'shared/utils/object';
import { messagePinkButtonStroke } from 'shared/components/MessageItem/utils';
import Scrollbars from 'react-custom-scrollbars';
import Loading from '../../../../../components/Loading/Loading';

const MessageDetail = props => {
  const { messageDetail, onPressHandler: onClickHandler, handleButtonLabel } = useMessageDetails(props);

  const [width, setWidth] = useState(0);

  const onScroll = () => {
    setWidth(0.5);
  };

  return (
    <Container>
      {messageDetail ? (
        <>
          <Header>
            <Button
              onClick={() => props.setActiveMessage(null)}
              imageSource={require(asset`Back_Arrow.png`)}
              imageStyle={Styles.arrowImage}
              containerStyle={Styles.arrowContainer}
            />
            <MessageTitle>
              {!isEmpty(messageDetail?.title) ? messageDetail?.title : messageDetail?.subject}
            </MessageTitle>
          </Header>
          <Divider src={require(asset`images/social/message-divider.png`)} />
          <Body>
            <ScrollbarContainer
              onScroll={onScroll}
              renderThumbVertical={props => (
                <div {...props} className="thumb-vertical" style={{ width: width + 'rem' }} />
              )}
              renderTrackVertical={props => (
                <div {...props} className="track-vertical" style={{ opacity: width > 0 ? 1 : 0 }} />
              )}
              renderThumbHorizontal={props => (
                <div {...props} className="thumb-horizontal" style={{ display: 'none' }} />
              )}
              renderTrackHorizontal={props => (
                <div {...props} className="track-horizontal" style={{ display: 'none' }} />
              )}
            >
              <MessageDescription>{messageDetail?.body}</MessageDescription>
              <ButtonWrapper>
                <Button
                  imageSource={require(asset`Pink_Button.png`)}
                  textStroke={messagePinkButtonStroke}
                  onClick={onClickHandler}
                  imageStyle={{}}
                  containerStyle={Styles.button}
                  textStyle={Styles.buttonText}
                  label={handleButtonLabel()}
                />
              </ButtonWrapper>
            </ScrollbarContainer>
          </Body>
        </>
      ) : (
        <Loading loading={!messageDetail} />
      )}
    </Container>
  );
};

export default MessageDetail;

const Container = styled.div`
  width: 100%;
  height: 100%;
  padding: 1rem;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex: 5;
  flex-direction: column;
`;

const Divider = styled.img`
  width: 100%;
  height: 35%;
  top: 12rem;
  position: absolute;
  object-fit: contain;
`;

const Header = styled.div`
  width: 85%;
  flex: 1;
  display: flex;
  justify-content: space-between;
  align-items: center;
  align-content: center;
  flex-wrap: wrap;
  height: 100%;
  margin: 0 auto;
  margin-top: 1rem;
`;

const MessageTitle = styled.p`
  font-size: 1.5rem;
  font-weight: 600;
  text-align: center;
  text-transform: uppercase;
  color: white;
  flex-grow: 1;
`;

const Body = styled.div`
  width: 100%;
  flex: 3;
  justify-content: center;
  align-items: flex-start;
  align-content: flex-start;
  display: flex;
  flex-wrap: wrap;
  padding: 1rem;
`;

const MessageDescription = styled.p`
  font-size: 1.1rem;
  font-weight: 400;
  text-align: center;
  color: white;
  padding: 1rem;
  white-space: pre-wrap;
`;

const ButtonWrapper = styled.div`
  width: 100%;
  margin-top: 3rem;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const ScrollbarContainer = styled(Scrollbars)`
  height: 460px;
`;

const Styles = {
  arrowContainer: {
    position: 'absolute',
    zIndex: 10,
    marginBottom: '1rem',
    left: '2rem',
  },
  arrowImage: {
    width: 30,
    height: 30,
  },
  button: {
    width: '100%',
    height: '100%',
  },
  buttonText: {
    fontSize: '1.2rem',
  },
};

