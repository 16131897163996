import { validateTimestamp } from '../../../utils/TimestampManager';
import { MISSIONS_REWARD_BUNDLE_TYPE, MISSIONS_STATES, MISSIONS_TYPES } from '../constants'; // manager by type

export var hasExplorerType = function hasExplorerType(missionType) {
  return missionType === MISSIONS_TYPES.EXPLORER;
};
export var hasGrandType = function hasGrandType(missionType) {
  return missionType === MISSIONS_TYPES.GRAND || missionType === MISSIONS_TYPES.MASTER;
}; // manager by states

export var hasReadyState = function hasReadyState(missionStatus) {
  return missionStatus === MISSIONS_STATES.READY;
};
export var hasRunningState = function hasRunningState(missionStatus) {
  return missionStatus === MISSIONS_STATES.RUNNING;
};
export var hasRewardState = function hasRewardState(missionStatus) {
  return missionStatus === MISSIONS_STATES.REWARD;
};
export var hasLockedState = function hasLockedState(missionStatus) {
  return missionStatus === MISSIONS_STATES.LOCKED;
};
export var hasSomeCompleteState = function hasSomeCompleteState(missionStatus) {
  return missionStatus === MISSIONS_STATES.REWARD || missionStatus === MISSIONS_STATES.COMPLETED || missionStatus === MISSIONS_STATES.FINISHED;
}; // manager by gameIds

export var hasGameIds = function hasGameIds(gameIds) {
  return gameIds && gameIds.length !== 0;
}; //manager by rewardType

export var hasMysteryRewardType = function hasMysteryRewardType(reward) {
  return reward.rewardBundleType === MISSIONS_REWARD_BUNDLE_TYPE.MYSTERY_GIFT;
};
export var hasMysteryRewardTypeNoRevealed = function hasMysteryRewardTypeNoRevealed(reward) {
  var _reward$rewardItems$;

  return reward.rewardBundleType === MISSIONS_REWARD_BUNDLE_TYPE.MYSTERY_GIFT && !((_reward$rewardItems$ = reward.rewardItems[0]) !== null && _reward$rewardItems$ !== void 0 && _reward$rewardItems$.isRevealed);
}; // manager functions

export var activateBottomBarAnimation = function activateBottomBarAnimation(missionsData) {
  return (missionsData === null || missionsData === void 0 ? void 0 : missionsData.some(function (x) {
    return hasRewardState(x.missionStatus);
  })) || missionsData.length > 1 && (missionsData === null || missionsData === void 0 ? void 0 : missionsData.slice(1).every(function (x) {
    return x.missionStatus !== MISSIONS_STATES.RUNNING;
  }));
};
export var hasRunningStatus = function hasRunningStatus(_ref) {
  var missionStatus = _ref.missionStatus,
      missionType = _ref.missionType;
  return hasRunningState(missionStatus) && hasExplorerType(missionType);
};
export var hasGameIdsAndRunningStatus = function hasGameIdsAndRunningStatus(item) {
  var _item$counter$params;

  return hasRunningStatus(item) && hasGameIds((_item$counter$params = item.counter.params) === null || _item$counter$params === void 0 ? void 0 : _item$counter$params.gameIds);
};
export var currentRunningMission = function currentRunningMission(missionsData) {
  return missionsData.find(function (x) {
    return (hasRunningState(x.missionStatus) || hasRewardState(x.missionStatus)) && hasExplorerType(x.missionType) && validateTimestamp(x.endTimestamp);
  });
};
export var hasSomeRewardState = function hasSomeRewardState(missionsData) {
  return missionsData.some(function (x) {
    return hasRewardState(x.missionStatus);
  });
};