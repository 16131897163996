import generateRender from 'shared/utils/generateRender';
import MessagesHeaderImage from './MessagesHeaderImage';
import MessagesHeader from './MessagesHeader';

const HeaderComponent = generateRender({
  default: MessagesHeaderImage,
  hri: MessagesHeader,
  millelacs: MessagesHeader,
  star: MessagesHeader,
  ti: MessagesHeader,
});

export default HeaderComponent;

