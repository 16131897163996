import _defineProperty from "/var/jenkins/workspace/Web Clients and Images - Production/Code/WEB CLIENT - GTP 2.0 Deploy/platforms/web/node_modules/babel-preset-react-app/node_modules/@babel/runtime/helpers/esm/defineProperty";
import _slicedToArray from "/var/jenkins/workspace/Web Clients and Images - Production/Code/WEB CLIENT - GTP 2.0 Deploy/platforms/web/node_modules/babel-preset-react-app/node_modules/@babel/runtime/helpers/esm/slicedToArray";

function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); enumerableOnly && (symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; })), keys.push.apply(keys, symbols); } return keys; }

function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = null != arguments[i] ? arguments[i] : {}; i % 2 ? ownKeys(Object(source), !0).forEach(function (key) { _defineProperty(target, key, source[key]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)) : ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } return target; }

import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useDispatch, useSelector, shallowEqual } from 'react-redux';
import Logger from '../../lib/analytics/logger';
import AppBackgroundType from '../../utils/AppBackgroundType';
import { setAppBackground } from '../../state/actions/app';
import { EVENT_TYPES } from '../../lib/analytics/constants';
import { isEmpty } from 'lodash';
import { GAME_ROOMS } from '../../state/query/games/utils';
import TimestampManager from '../../utils/TimestampManager';
import { setFirstTimeVisitVipRoom } from '../../state/actions/ftue';
import Screens from '..';
import QueryParamsProxy from '../../utils/queryParamProxy';
import routeParamConstants from '../../utils/routeParamConstants';
export var PAGE_SIZE = 8;

var useSportGames = function useSportGames() {
  var dispatch = useDispatch();

  var _useSelector = useSelector(function (state) {
    var _state$sportsGames, _state$sportsGames$ga, _state$consumables$ac, _state$consumables$ac2, _state$consumables$ac3;

    return {
      gameRooms: state.sportsGames,
      lobbyPromotions: state.promotions.lobbyNews,
      hasSportsRoomVip: ((_state$sportsGames = state.sportsGames) === null || _state$sportsGames === void 0 ? void 0 : (_state$sportsGames$ga = _state$sportsGames.gameList[0]) === null || _state$sportsGames$ga === void 0 ? void 0 : _state$sportsGames$ga.source) === GAME_ROOMS.CONFIGURABLE_VIP,
      activeConsumablesVipRoomSecconds: (_state$consumables$ac = (_state$consumables$ac2 = state.consumables.active) === null || _state$consumables$ac2 === void 0 ? void 0 : (_state$consumables$ac3 = _state$consumables$ac2.vip_room_access) === null || _state$consumables$ac3 === void 0 ? void 0 : _state$consumables$ac3.seconds_until_expiry) !== null && _state$consumables$ac !== void 0 ? _state$consumables$ac : 0,
      showVipAccessModal: state.consumables.showVipAccessModal,
      isVipRoomUnlocked: state.consumables.isVipRoomUnlocked
    };
  }, shallowEqual),
      gameRooms = _useSelector.gameRooms,
      lobbyPromotions = _useSelector.lobbyPromotions,
      hasSportsRoomVip = _useSelector.hasSportsRoomVip,
      activeConsumablesVipRoomSecconds = _useSelector.activeConsumablesVipRoomSecconds,
      showVipAccessModal = _useSelector.showVipAccessModal,
      isVipRoomUnlocked = _useSelector.isVipRoomUnlocked;

  var _useState = useState({
    timeRemaining: 0,
    canEnterRoom: true,
    games: [],
    featuredGames: [],
    panelList: [],
    numColumn: 0,
    pagedGames: []
  }),
      _useState2 = _slicedToArray(_useState, 2),
      sportGames = _useState2[0],
      setSportGames = _useState2[1];

  var timer = useRef(null);
  var deactivateTimer = useRef(null);
  var panelList = useMemo(function () {
    var _gameRooms$gameList$;

    return ((gameRooms === null || gameRooms === void 0 ? void 0 : (_gameRooms$gameList$ = gameRooms.gameList[0]) === null || _gameRooms$gameList$ === void 0 ? void 0 : _gameRooms$gameList$.games) || []).filter(function (game) {
      return !game.IS_HIDDEN;
    });
  }, [gameRooms]);
  var getLivePanelList = useCallback(function () {
    var pagedGames = Array.from({
      length: Math.ceil(panelList.length / PAGE_SIZE)
    }, function (_, index) {
      return panelList.slice(index * PAGE_SIZE, (index + 1) * PAGE_SIZE);
    });
    setSportGames(function (sportGames) {
      return _objectSpread(_objectSpread({}, sportGames), {}, {
        panelList: panelList,
        numColumn: 1,
        pagedGames: pagedGames,
        pageNumber: 0
      });
    });
  }, [panelList]);
  var registerTimer = useCallback(function () {
    if (!timer.current) timer.current = TimestampManager.register(activeConsumablesVipRoomSecconds * 1000, function () {
      setSportGames(function (sportGames) {
        return _objectSpread(_objectSpread({}, sportGames), {}, {
          timeRemaining: 0,
          canEnterRoom: false
        });
      });
    });
  }, [activeConsumablesVipRoomSecconds, timer]);
  var handleTimeRemaining = useCallback(function () {
    var newTimeRemaining = Date.now() + activeConsumablesVipRoomSecconds * 1000;

    if (newTimeRemaining > 0) {
      setSportGames(function (sportGames) {
        return _objectSpread(_objectSpread({}, sportGames), {}, {
          timeRemaining: newTimeRemaining
        });
      });
      dispatch(setFirstTimeVisitVipRoom(false));
      registerTimer();
    } else if (hasSportsRoomVip && showVipAccessModal && !isVipRoomUnlocked) {
      deactivateTimer.current = window.setTimeout(function () {
        QueryParamsProxy.setQueryParams(_defineProperty({}, routeParamConstants.PARAM_MODAL, Screens.VipAccessFlow));
      }, 800);
    }
  }, [activeConsumablesVipRoomSecconds, dispatch, hasSportsRoomVip, isVipRoomUnlocked, registerTimer, showVipAccessModal]);
  useEffect(function () {
    getLivePanelList();
    handleTimeRemaining();
    dispatch(setAppBackground(hasSportsRoomVip ? AppBackgroundType.VIP_GAMES : AppBackgroundType.NORMAL));
    Logger.sendEvent(EVENT_TYPES.GAME_ROOM_LOADED, {
      name: 'sportGames'
    });
    return function () {
      dispatch(setAppBackground(AppBackgroundType.NORMAL));
      TimestampManager.unregister(timer.current);
      window.clearTimeout(deactivateTimer.current);
    };
  }, [dispatch, getLivePanelList, handleTimeRemaining, hasSportsRoomVip, timer]);
  return _objectSpread(_objectSpread({}, sportGames), {}, {
    hasPromotions: !isEmpty(lobbyPromotions),
    hasGames: !isEmpty(sportGames.pagedGames),
    hasSportsRoomVip: hasSportsRoomVip
  });
};

export default useSportGames;