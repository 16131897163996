import { createNavigator, SwitchRouter } from '@react-navigation/core';
import { createBrowserApp } from '@react-navigation/web';
import React, { useEffect } from 'react';
import { isMobile, withOrientationChange } from 'react-device-detect';
import ENVIRONMENT_VARIABLES from 'shared/utils/environmentVariables';
import SmartBanner from './components/SmartBanner';
import { EVENT_TYPES, TARGET_TYPES } from './lib/analytics/constants';
import { Logger } from './lib/analytics/logger';

/* Navigation Wrapper */
import AuthSwitch from './components/AuthSwitch/AuthSwitch';
import LobbyOverlay from './components/LobbyOverlay';

/* Screens Imports */
import Screens from 'shared/screens';
import FinishPurchase from './components/WebFinishPurchase';
import AdBlocker from './screens/AdBlocker';
import DynamicPopups from './screens/DynamicPopups';
import FavouriteOnboardingDialogue from './screens/FavouriteHelper';
import FavouriteListDialogue from './screens/FavouriteListDialogue';
import Favourites from './screens/Favourites';
import ForgotPassword from './screens/ForgotPassword';
import Game from './screens/Game';
import Home from './screens/Home';
import LinkAccount from './screens/LinkAccount';
import LinkLoyaltyCard from './screens/LinkLoyaltyCard';
import Login from './screens/Login/Login';
import Missions from './screens/Missions';
import OutOfCoin from './screens/OutOfCoin';
import PurchaseSucceeded from './screens/PurchaseSucceeded';
import SessionExpired from './screens/SessionExpired';
import Signup from './screens/Signup';
import SneakPeekOnboarding from './screens/SneakPeekOnboarding';
import Start from './screens/Start';
import TableGames from './screens/TableGames';
import TrackingURL from './screens/TrackingURL';
import Congrats from './screens/VideoAds/Congrats';
import WatchAndEarnInfo from './screens/VideoAds/Info';
import VipAccessFlow from './screens/VipAccessFlow';
import VIPGames from './screens/VIPGames';
import WelcomeBonus from './screens/WelcomeBonus';
import Events from './screens/Events/Events';
import RewardCenter from './screens/RewardCenter';
import SportGames from './screens/SportGames/SportGames';

/* Utils */
import Utils from 'shared/utils';

/* Store and data */
import { connect } from 'shared/node_modules/react-redux';
import { progressiveJackpotsRequestData } from 'shared/state/slices/progressiveJackpots/progressiveJackpots';

/* Query String Stuff */

/** Notifications */
import { PushNotification } from './lib/notifications/PushNotification';

// CSS
import './App.scss';

// Hooks
import useFeatureFlags from 'shared/hooks/useFeatureFlags';

// Constants
import { GET_PROGRESSIVE_JACKPOTS_SCREENS } from 'shared/constants';
import { FE_PROGRESSIVE_JACKPOTS } from 'shared/utils/featureFlags/constants';
import navigation from 'shared/utils/navigation/navigation';
import QueryParamProxy from 'shared/utils/queryParamProxy';

// Init QPP
QueryParamProxy.init();

const LobbyStack = createNavigator(
  LobbyOverlay,
  SwitchRouter(
    {
      [Screens.Home]: { screen: Home, path: 'slots' },
      [Screens.Game]: {
        screen: Game,
        path: `game/:gid`,
      },
      [Screens.Favourites]: { screen: Favourites, path: 'favourites' },
      [Screens.FavouriteOnboardingDialogue]: {
        screen: FavouriteOnboardingDialogue,
        path: 'favouriteOnboardingDialogue',
      },
      [Screens.FavouriteListDialogue]: { screen: FavouriteListDialogue, path: 'favouriteListDialogue' },
      [Screens.Tables]: { screen: TableGames, path: 'tables' },
      [Screens.VIP]: { screen: VIPGames, path: 'vip' },
      [Screens.Sports]: { screen: SportGames, path: 'sports' },
      [Screens.VipAccessFlow]: { screen: VipAccessFlow, path: 'vipAccessFlow' },
      [Screens.PurchaseSucceeded]: { screen: PurchaseSucceeded, path: 'purchaseSucceeded' },
      [Screens.SneakPeekOnboarding]: { screen: SneakPeekOnboarding, path: 'sneakPeekOnboarding' },
      [Screens.WatchAndEarnInfo]: { screen: WatchAndEarnInfo, path: 'watchAndEarnInfo' },
      [Screens.OutOfCoin]: { screen: OutOfCoin, path: 'outOfCoin' },
      [Screens.LinkAccount]: { screen: LinkAccount, path: 'linkAccount' },
      [Screens.Congrats]: { screen: Congrats, path: 'congrats' },
      [Screens.WelcomeBonus]: { screen: WelcomeBonus, path: 'welcomeBonus' },
      [Screens.AdBlocker]: { screen: AdBlocker, path: 'adBlocker' },
      [Screens.Missions]: { screen: Missions, path: 'missions' },
      [Screens.DynamicPopups]: { screen: DynamicPopups, path: 'DynamicPopups' },
      [Screens.Events]: { screen: Events, path: 'Events' },
      [Screens.RewardCenter]: { screen: RewardCenter, path: 'RewardCenter' },
    },
    {
      backBehavior: 'history',
    }
  ),
  {
    initialRouteName: Screens.Home,
  }
);

// SSO will redirect back to a particular path, so we handle that if we are using SSO
const ssoPath = ENVIRONMENT_VARIABLES.SSO_CALLBACK_PATH;
const ssoEntry =
  ENVIRONMENT_VARIABLES.SSO_ENABLED === 'true'
    ? {
        [ssoPath]: {
          screen: Login,
          path: ENVIRONMENT_VARIABLES.SSO_CALLBACK_PATH,
        },
      }
    : undefined;
const screens = {
  [Screens.Start]: Start,
  [Screens.SessionExpired]: SessionExpired,
  [Screens.Login]: Login,
  [Screens.ForgotPassword]: ForgotPassword,
  [Screens.Signup]: Signup,
  [Screens.TrackingURL]: {
    screen: TrackingURL,
    path: 'externallanding/:aff_id',
    parse: {
      aff_id: aff_id => aff_id.replace(/^@/, ''),
    },
  },
  [Screens.Lobby]: {
    screen: LobbyStack,
    path: 'home',
  },
  [Screens.FinishWebPurchase]: {
    screen: FinishPurchase,
  },
  [Screens.LinkLoyaltyCard]: LinkLoyaltyCard,
  ...ssoEntry,
};

const RootStack = createNavigator(AuthSwitch, SwitchRouter(screens), {
  initialRouteName: Screens.Start,
});

const App = createBrowserApp(RootStack);

let loggerTargets = [
  {
    name: TARGET_TYPES.DIVE,
  },
  {
    name: TARGET_TYPES.FACEBOOK_PIXEL,
  },
];

if (ENVIRONMENT_VARIABLES.XTREMEPUSH_ID) {
  loggerTargets.push({
    name: TARGET_TYPES.XTREMEPUSH,
  });
}

PushNotification.initialize();

const OrientationChange = withOrientationChange(({ children, isLandscape, isPortrait }) => {
  if (!isMobile) {
    document.querySelector('head meta[name="viewport"]').content =
      'width=device-width, initial-scale=1, shrink-to-fit=no';
  } else if (isLandscape) {
    document.querySelector('head meta[name="viewport"]').content =
      'width=device-width, initial-scale=0.5, shrink-to-fit=no';
  } else if (isPortrait) {
    document.querySelector('head meta[name="viewport"]').content =
      'width=device-width, initial-scale=0.25, shrink-to-fit=no';
  }
  return (
    <>
      {' '}
      {children} <SmartBanner />
    </>
  );
});

const mapStateToProps = state => {
  return {
    featureFlags: state.featureFlags.enabledFeatures,
  };
};

const mapDispatchToProps = dispatch => {
  return {
    progressiveJackpotsRequestData: () => {
      dispatch(progressiveJackpotsRequestData());
    },
  };
};

const AppComponent = props => {
  const { featureFlags, progressiveJackpotsRequestData } = props;
  const { hasFeature } = useFeatureFlags();
  const hasProgressiveJackpots = hasFeature(FE_PROGRESSIVE_JACKPOTS);
  Logger.initialize({ targets: loggerTargets });

  useEffect(() => {
    hasProgressiveJackpots && progressiveJackpotsRequestData();
  }, [featureFlags, hasProgressiveJackpots, progressiveJackpotsRequestData]);

  return (
    <OrientationChange>
      <App
        ref={navigatorRef => {
          navigation.setTopLevelNavigator(navigatorRef);
        }}
        onNavigationStateChange={(prevState, currentState) => {
          const currentRouteName = Utils.getActiveRouteName(currentState);

          // request jackpots when navigating to a screen where these games can be seen and the feature is enabled
          if (GET_PROGRESSIVE_JACKPOTS_SCREENS.includes(currentRouteName) && hasProgressiveJackpots) {
            progressiveJackpotsRequestData();
          }

          Logger.sendEvent(EVENT_TYPES.VIEW);
        }}
      />
    </OrientationChange>
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(AppComponent);

