import React from 'react';
import audio from 'shared/utils/audio';
import { Container, TabContainer, TabImage, TabsWrapper, PurpleUnderline } from './styledComponents';
import useThemeContext from 'shared/context/hooks/useThemeContext';

const ControlHeader = props => {
  const { activeTab, setActiveTab, setPrevTab, rewardCenterAssets, profileTabs } = props;
  const { RewardCenter } = useThemeContext();

  const renderTab = tab => {
    if (!tab.show) return null;

    const isSelected = activeTab === tab.key;

    return (
      <TabContainer
        key={tab.key}
        onClick={() => {
          audio.onClick();
          setPrevTab(activeTab);
          setActiveTab(tab.key);
        }}
        className={`RewardCenterTab${isSelected ? ' Active' : ''}`}
        data-tab-name={tab.key}
      >
        <TabImage
          theme={isSelected ? RewardCenter.ActiveTabImage : RewardCenter.TabImage}
          src={isSelected ? tab.selectedImage : tab.defaultImage}
          alt=""
        />
      </TabContainer>
    );
  };

  return (
    <Container>
      <div className="RewardCenterHeader">
        <TabsWrapper>{profileTabs.map(renderTab)}</TabsWrapper>
      </div>
      <PurpleUnderline src={rewardCenterAssets.REWARD_CENTER_MENU_UNDERLINE} alt="" />
    </Container>
  );
};

export default ControlHeader;

