export var ASSETS_KEYS = {
  /**
   * Missions asset keys
   */
  BACKGROUND: 'background/background.png',
  COMING_SOON: 'background/coming-soon.png',
  NO_MISSIONS: 'background/no-missions.png',
  NOTIFICATION: 'notification/notification.png',
  GRAND_PRIZE: 'cards/card-master-prize/master-prize-frame.png',
  GRAND_PRIZE_IMAGE: 'cards/card-master-prize/master-prize-image.png',
  CARD_BLUE: 'cards/card-background/card-blue.png',
  CARD_PURPLE: 'cards/card-background/card-purple.png',
  CARD_ORANGE: 'cards/card-textbox/textbox-orange.png',
  CARD_GREEN: 'cards/card-textbox/textbox-green.png',
  CARD_SHADOW: 'cards/card-textbox/textbox-shadow.png',
  COIN_IMAGE: 'cards/card-images/image-coins.png',
  COLLECT_BONUS_IMAGE: 'cards/card-images/image-collect-bonus.png',
  FREE_SPINS_IMAGE: 'cards/card-images/image-free-spins.png',
  GAME_ICON_IMAGE: 'cards/card-images/image-game.png',
  LEVEP_UP_IMAGE: 'cards/card-images/image-level-up.png',
  UNLOCK_IMAGE: 'cards/card-images/image-unlock.png',
  BET_TEXT: 'cards/card-titles/title-bet.png',
  COLLECT_BONUS_TEXT: 'cards/card-titles/title-collect-bonus.png',
  FREE_SPINS_TEXT: 'cards/card-titles/title-free-spins.png',
  LEVEP_UP_TEXT: 'cards/card-titles/title-level-up.png',
  MAX_BET_TEXT: 'cards/card-titles/title-max-bet.png',
  SPIN_TEXT: 'cards/card-titles/title-spin.png',
  UNLOCK_TEXT: 'cards/card-titles/title-unlock.png',
  WIN_TEXT: 'cards/card-titles/Win.png',
  BAR_BASE_BLUE: 'cards/card-progress-bar/bar-base-blue.png',
  BAR_BASE_PURPLE: 'cards/card-progress-bar/bar-base-purple.png',
  BAR_FILL_SIDE: 'cards/card-progress-bar/bar-fill-side.png',
  BAR_FILL: 'cards/card-progress-bar/bar-fill.png',
  BAR_SHADOW: 'cards/card-progress-bar/bar-shadow.png',
  BAR_COLLECT_BUTTON: 'cards/card-progress-bar/collect-button.png',
  BAR_LOCKED_ICON: 'cards/card-icon/padlock.png',
  BAR_PLAY_ICON: 'cards/card-icon/play-button.png',
  TOP_BAR_ICON: 'top-bar/mission-icon.png',
  TOP_BAR_BASE: 'top-bar/top-bar-base.png',
  TOP_BAR_COLLECT: 'top-bar/top-bar-collect-button.png',
  TOP_BAR_FILL: 'top-bar/top-bar-fill.png',
  TOP_BAR_SIDE: 'top-bar/top-bar-side.png',
  TOP_BAR_TIMER: 'top-bar/top-bar-timer.png',
  TABS_BATTLES_1: 'tabs/battles/battles-1.png',
  TABS_BATTLES_2: 'tabs/battles/battles-2.png',
  TABS_BATTLES_3: 'tabs/battles/battles-3.png',
  TABS_EXPERT_1: 'tabs/expert/expert-1.png',
  TABS_EXPERT_2: 'tabs/expert/expert-2.png',
  TABS_EXPERT_3: 'tabs/expert/expert-3.png',
  TABS_EXPLORER_1: 'tabs/explorer/explorer-1.png',
  TABS_EXPLORER_2: 'tabs/explorer/explorer-2.png',
  TABS_EXPLORER_3: 'tabs/explorer/explorer-3.png',
  TUTORIAL_INFO_BUTTON: 'tutorial/info-button/info-button.png',
  TUTORIAL_INFO_SCREEN_STEP_1: 'tutorial/info-screen/info-screen-1.png',
  TUTORIAL_INFO_SCREEN_STEP_2: 'tutorial/info-screen/info-screen-2.png',
  TUTORIAL_INFO_SCREEN_STEP_3: 'tutorial/info-screen/info-screen-3.png',
  TUTORIAL_INFO_SCREEN_ARROW: 'tutorial/info-screen/info-screen-arrow.png',
  TUTORIAL_INFO_SCREEN_CLOSE_BTN: 'tutorial/info-screen/info-screen-close.png',
  TUTORIAL_TOOLTIP_STEP_1: 'tutorial/tooltips/tooltip-0.png',
  TUTORIAL_TOOLTIP_STEP_2: 'tutorial/tooltips/tooltip-1.png',
  TUTORIAL_TOOLTIP_STEP_3: 'tutorial/tooltips/tooltip-2.png',
  TUTORIAL_TOOLTIP_STEP_4: 'tutorial/tooltips/tooltip-3.png',
  TUTORIAL_TOOLTIP_STEP_5: 'tutorial/tooltips/tooltip-4.png',
  TUTORIAL_TOOLTIP_STEP_6: 'tutorial/tooltips/tooltip-5.png',
  TUTORIAL_TOOLTIP_STEP_7: 'tutorial/tooltips/tooltip-6.png',
  TUTORIAL_TOOLTIP_STEP_8: 'tutorial/tooltips/tooltip-7.png',
  TUTORIAL_TOOLTIP_STEP_9: 'tutorial/tooltips/tooltip-8.png',
  TUTORIAL_TOOLTIP_STEP_10: 'tutorial/tooltips/tooltip-9.png',
  ANIMATIONS_LIGHT_SWEEP_03: 'animations/progress-bar/light-sweep-00.png',
  ANIMATIONS_LIGHT_SWEEP_04: 'animations/progress-bar/light-sweep-01.png',
  ANIMATIONS_LIGHT_SWEEP_05: 'animations/progress-bar/light-sweep-02.png',
  ANIMATIONS_LIGHT_SWEEP_06: 'animations/progress-bar/light-sweep-03.png',
  ANIMATIONS_LIGHT_SWEEP_07: 'animations/progress-bar/light-sweep-04.png',
  ANIMATIONS_LIGHT_SWEEP_08: 'animations/progress-bar/light-sweep-05.png',
  ANIMATIONS_LIGHT_SWEEP_09: 'animations/progress-bar/light-sweep-06.png',
  ANIMATIONS_LIGHT_SWEEP_10: 'animations/progress-bar/light-sweep-07.png',
  ANIMATIONS_LIGHT_SWEEP_11: 'animations/progress-bar/light-sweep-08.png',
  ANIMATIONS_LIGHT_SWEEP_12: 'animations/progress-bar/light-sweep-09.png',
  ANIMATIONS_LIGHT_SWEEP_13: 'animations/progress-bar/light-sweep-10.png',
  ANIMATIONS_LIGHT_SWEEP_14: 'animations/progress-bar/light-sweep-11.png',
  ANIMATIONS_LIGHT_SWEEP_15: 'animations/progress-bar/light-sweep-12.png',
  ANIMATIONS_LIGHT_SWEEP_16: 'animations/progress-bar/light-sweep-13.png',
  ANIMATIONS_LIGHT_SWEEP_17: 'animations/progress-bar/light-sweep-14.png',
  ANIMATIONS_LIGHT_SWEEP_18: 'animations/progress-bar/light-sweep-15.png',
  ANIMATIONS_LIGHT_SWEEP_19: 'animations/progress-bar/light-sweep-16.png',
  ANIMATIONS_LIGHT_SWEEP_20: 'animations/progress-bar/light-sweep-17.png',
  ANIMATIONS_LIGHT_SWEEP_21: 'animations/progress-bar/light-sweep-18.png',
  ANIMATIONS_LIGHT_SWEEP_22: 'animations/progress-bar/light-sweep-19.png',
  ANIMATIONS_LIGHT_SWEEP_23: 'animations/progress-bar/light-sweep-20.png',
  ANIMATIONS_LIGHT_SWEEP_24: 'animations/progress-bar/light-sweep-21.png',
  ANIMATIONS_LIGHT_SWEEP_25: 'animations/progress-bar/light-sweep-22.png',
  ANIMATIONS_LIGHT_SWEEP_26: 'animations/progress-bar/light-sweep-23.png',
  ANIMATIONS_LIGHT_SWEEP_27: 'animations/progress-bar/light-sweep-24.png',
  ANIMATIONS_LIGHT_SWEEP_28: 'animations/progress-bar/light-sweep-25.png',
  ANIMATIONS_LIGHT_SWEEP_29: 'animations/progress-bar/light-sweep-26.png',
  TOOLTIP_BACKING: 'tooltip/tooltip-backing.png',
  CARD_BLUE_FRAME: 'cards/card-frame/card-blue-frame.png',
  CARD_PURPLE_FRAME: 'cards/card-frame/card-purple-frame.png',
  MODAL_BACKGROUND: 'modal-background.png',

  /**
   * Daily Spin asset keys
   */
  DAILY_SPIN_BACKGROUND: 'background/background.jpg',
  DAILY_SPIN_CALENDAR_ICON: 'bottom-bar/calendar.png',
  DAILY_SPIN_CARDS_ICON: 'bottom-bar/cards.png',
  DAILY_SPIN_BOTTOM_BOX: 'bottom-bar/bottom-inset.png',
  DAILY_SPIN_WHEEL_ICON: 'bottom-bar/wheel.png',
  DAILY_SPIN_TEXT_LOGIN: 'bottom-bar/text-login.png',
  DAILY_SPIN_TEXT_TOTAL: 'bottom-bar/text-total.png',
  DAILY_SPIN_BOTTOM_BACKGROUND: 'bottom-bar/bottom-background.png',
  DAILY_SPIN_WHEEL: 'wheel/wheel-big.png',
  DAILY_SPIN_WHEEL_TICKER: 'wheel/wheel-ticker.png',
  DAILY_SPIN_WHEEL_FRAME: 'wheel/wheel-frame.png',
  DAILY_SPIN_WHEEL_SPIN_BTN: 'wheel/wheel-spin-button.png',
  DAILY_SPIN_WHEEL_BTN_LOGO: 'wheel/wheel-logo-button.png',
  DAILY_SPIN_PRIZE_STACK: 'wheel/coin-stack.png',
  DAILY_SPIN_WHEEL_SKIP_BTN: 'wheel/skip-button.png',
  DAILY_SPIN_COLUMN_BACKGROUND: 'column/column-background.png',
  DAILY_SPIN_STREAK_ROW_DIVIDER: 'column/row-divider.png',
  DAILY_SPIN_STREAK_DAY_INDICATOR: 'column/row-indicator.png',
  DAILY_SPIN_COLUMN_LIGHT_1: 'column/light-1.png',
  DAILY_SPIN_COLUMN_LIGHT_2: 'column/light-2.png',
  DAILY_SPIN_COLUMN_LIGHT_3: 'column/light-3.png',
  DAILY_SPIN_COLUMN_LIGHT_4: 'column/light-4.png',
  DAILY_SPIN_COLUMN_LIGHT_5: 'column/light-5.png',
  DAILY_SPIN_COLUMN_LIGHT_6: 'column/light-6.png',
  DAILY_SPIN_COLUMN_LIGHT_7: 'column/light-7.png',
  DAILY_SPIN_COLUMN_LIGHT_8: 'column/light-8.png',
  DAILY_SPIN_COLUMN_LIGHT_9: 'column/light-9.png',
  DAILY_SPIN_COLUMN_LIGHT_OFF: 'column/light-off.png',
  DAILY_SPIN_COLUMN_RIBBON: 'column/ribbon.png',
  DAILY_SPIN_COLUMN_RIBBON_HERO: 'column/ribbon-hero.png',
  DAILY_SPIN_COLUMN_INFO_ICON: 'column/info-icon.png',
  DAILY_SPIN_COLUMN_INFO_POPUP: 'column/info-popup.png',
  DAILY_SPIN_MILESTONE_CIRCLE: 'column/prize-circle.png',
  DAILY_SPIN_INFO_POPUP: 'info/daily-spin-info.png',

  /**
   * Sweeptakes Spin asset keys
   */
  EVENTS_BACKGROUND: 'background/sweepstakes-background.png',
  EVENTS_CARD_BLUE: 'cards/cards-background/cards-blue.png',
  EVENTS_CARD_PURPLE: 'cards/cards-background/cards-purple.png',
  EVENTS_CARD_ORANGE: 'cards/cards-background/cards-orange.png',
  EVENTS_CARD_MULTIlPLE_GAMES: 'cards/cards-background/cards-multiple-games.png',
  EVENTS_CARD_MULTIlPLE_ACTIVITIES: 'cards/cards-background/cards-multiple-activities.png',
  EVENTS_CARD_ALL_GAMES: 'cards/cards-background/cards-all-games.png',
  EVENTS_CARD_BOTTOM_BLUE: 'cards/cards-bottom/cards-bottom-blue.png',
  EVENTS_CARD_BOTTOM_PURPLE: 'cards/cards-bottom/cards-bottom-purple.png',
  EVENTS_CARD_BOTTOM_ORANGE: 'cards/cards-bottom/cards-bottom-orange.png',
  EVENTS_CARD_BOTTOM_PINK: 'cards/cards-bottom/cards-bottom-pink.png',
  EVENTS_CARD_FRAME_GLOW: 'cards/cards-frame/cards-frame-glow.png',
  EVENTS_CARD_FRAME_GLOW_LIVE_OPS: 'cards/cards-frame/cards-frame-glow-orange.png',
  EVENTS_CARD_FRAME_SHADOW: 'cards/cards-frame/cards-frame-shadow.png',
  EVENTS_CARD_IMAGE_LIVE_OPS: 'cards/cards-image/cards-events-live-ops.png',
  EVENTS_MULTIPLE_ACTIVITIES_TITLE: 'cards/cards-title/cards-multiple-activities-title.png',
  EVENTS_MULTIPLE_GAMES_TITLE: 'cards/cards-title/cards-multiple-games-title.png',
  EVENTS_ALL_GAMES_TITLE: 'cards/cards-title/cards-all-games-title.png',
  EVENTS_PODIUM_ACTIVE: 'podium/podium-active.png',
  EVENTS_PODIUM_DISABLED: 'podium/podium-disabled.png',
  EVENTS_PODIUM_LIGHT: 'podium/podium-light.png',
  EVENTS_DEFAULT_RIBBON: 'ribbon/default-ribbon/default-ribbon.png',
  EVENTS_DEFAULT_RIBBON_SHADOW: 'ribbon/default-ribbon/default-ribbon-shadow.png',
  EVENTS_MAIN_RIBBON_TITLE: 'ribbon/main-ribbon/main-ribbon.png',
  EVENTS_MAIN_RIBBON_CROWN: 'ribbon/main-ribbon/main-ribbon-crown.png',
  EVENTS_MAIN_RIBBON_TROMBONE: 'ribbon/main-ribbon/main-ribbon-trombone.png',
  EVENTS_MAIN_RIBBON_TITLE_SHADOW: 'ribbon/main-ribbon/main-ribbon-shadow.png',
  EVENTS_MAIN_RIBBON_CROWN_SHADOW: 'ribbon/main-ribbon/main-ribbon-crown-shadow.png',
  EVENTS_MAIN_RIBBON_TROMBONE_SHADOW: 'ribbon/main-ribbon/main-ribbon-trombone-shadow.png',
  EVENTS_DETAIL_CLOSE_BUTTON: 'details/details-background/close-button.png',
  EVENTS_DETAIL_CARD_BLUE: 'details/details-background/details-blue.png',
  EVENTS_DETAIL_CARD_PURPLE: 'details/details-background/details-purple.png',
  EVENTS_DETAIL_CARD_ORANGE: 'details/details-background/details-orange.png',
  EVENTS_DETAIL_CARD_PINK: 'details/details-background/details-pink.png',
  EVENTS_DETAIL_CARD_GREY: 'details/details-background/details-grey.png',
  EVENTS_DETAIL_COUNTER_BLUE: 'details/details-counter/details-counter-container/details-counter-container-blue.png',
  EVENTS_DETAIL_COUNTER_PURPLE: 'details/details-counter/details-counter-container/details-counter-container-purple.png',
  EVENTS_DETAIL_COUNTER_ORANGE: 'details/details-counter/details-counter-container/details-counter-container-orange.png',
  EVENTS_DETAIL_COUNTER_PINK: 'details/details-counter/details-counter-container/details-counter-container-pink.png',
  EVENTS_DETAIL_COUNTER_GREY: 'details/details-counter/details-counter-container/details-counter-container-grey.png',
  EVENTS_DETAIL_BAR_BLUE: 'details/details-counter/details-counter-bar/details-counter-bar-blue.png',
  EVENTS_DETAIL_BAR_PURPLE: 'details/details-counter/details-counter-bar/details-counter-bar-purple.png',
  EVENTS_DETAIL_BAR_ORANGE: 'details/details-counter/details-counter-bar/details-counter-bar-orange.png',
  EVENTS_DETAIL_BAR_PINK: 'details/details-counter/details-counter-bar/details-counter-bar-pink.png',
  EVENTS_DETAIL_BAR_GREY: 'details/details-counter/details-counter-bar/details-counter-bar-grey.png',
  EVENTS_DETAIL_FILLABLE_BAR_BASE_ORANGE: 'details/details-counter/details-counter-filleable/details-counter-filleable-base-orange.png',
  EVENTS_DETAIL_FILLABLE_BAR_FILL_ORANGE: 'details/details-counter/details-counter-filleable/details-counter-filleable-fill-orange.png',
  EVENTS_DETAIL_FILLABLE_BAR_CAP_ORANGE: 'details/details-counter/details-counter-filleable/details-counter-filleable-cap-orange.png',
  EVENTS_DETAIL_FILLABLE_BAR_BASE_GREY: 'details/details-counter/details-counter-filleable/details-counter-filleable-base-grey.png',
  EVENTS_DETAIL_FILLABLE_BAR_FILL_GREY: 'details/details-counter/details-counter-filleable/details-counter-filleable-fill-grey.png',
  EVENTS_DETAIL_FILLABLE_BAR_CAP_GREY: 'details/details-counter/details-counter-filleable/details-counter-filleable-cap-grey.png',
  EVENTS_DETAIL_CARD_LOGO: 'details/details-logo/details-logo.png',
  EVENTS_TOOTLIP: 'tooltip/tooltip.png',
  EVENTS_NOTIFICATION_BADGE: 'icons/notification-badge.png',
  EVENTS_ICON_ACTIVE: 'icons/events-active.png',
  EVENTS_ERROR: 'icons/events-error.png',
  EVENTS_COMING_SOON: 'icons/events-coming-soon.png',
  EVENTS_MUST_UPDATE: 'icons/events-must-update.png',
  EVENTS_TUTORIAL_SLIDE_ONE_ICON: 'tutorial/info-targeted-events-icon.png',
  EVENTS_TUTORIAL_SLIDE_TWO_ICON: 'tutorial/info-sweepstakes-icons.png',
  EVENTS_TUTORIAL_SLIDE_THREE_ICON: 'tutorial/info-entries-icon.png',
  EVENTS_TUTORIAL_SLIDE_FOUR_ICON: 'tutorial/info-messages-icon.png',
  EVENTS_TUTORIAL_SLIDE_INDICATOR_SELECTED: 'tutorial/info-dot-selected.png',
  EVENTS_TUTORIAL_SLIDE_INDICATOR: 'tutorial/info-dot-unselected.png',
  EVENTS_TUTORIAL_INFO_MODAL_BG: 'tutorial/info-modal.png',
  EVENTS_TUTORIAL_INFO_ICON: 'tutorial/info-information-button.png',
  EVENTS_TUTORIAL_CLOSE_ICON: 'tutorial/info-close-button.png',

  /**
   * Reward Center asset keys
   */
  REWARD_CENTER_ONBOARDING: "onboarding-modal/onboarding.png",
  REWARD_CENTER_BACKGROUND: "main-screen/background/main-screen-background.jpg",
  REWARD_CENTER_FRAME_CARD: "main-screen/frame-card/main-screen-frame-card.png",
  REWARD_CENTER_FRAME_CARD_DISABLED: "main-screen/frame-card/main-screen-frame-card-disabled.png",
  REWARD_CENTER_FRAME_CARD_SHADOW: "main-screen/frame-card/main-screen-frame-card-shadow.png",
  REWARD_CENTER_OFFER_FRAME_CARD: "offer-details/offer-details-frame-card.png",
  REWARD_CENTER_PROFILE_ICON: "main-screen/profile/profile-icon.png",
  REWARD_CENTER_RIBBON_EXCLUSIVE: "main-screen/ribbons/exclusive-ribbon.png",
  REWARD_CENTER_RIBBON_FEATURED: "main-screen/ribbons/featured-ribbon.png",
  REWARD_CENTER_RIBBON_HOT: "main-screen/ribbons/hot-ribbon.png",
  REWARD_CENTER_RIBBON_LIMITED: "main-screen/ribbons/limited-ribbon.png",
  REWARD_CENTER_RIBBON_NEW: "main-screen/ribbons/new-ribbon.png",
  REWARD_CENTER_RIBBON_SOLD_OUT: "main-screen/ribbons/sold-out-ribbon.png",
  REWARD_CENTER_IMAGE_COINS: "coins-cards/coin-card-3.png",
  REWARD_CENTER_CLOSED_MENU_BACKGROUND: "menu/closed-menu/closed-menu-background.png",
  REWARD_CENTER_CLOSED_MENU_TITLE: "menu/closed-menu/closed-menu-title.png",
  REWARD_CENTER_CLOSED_MENU_CATEGORIES: "menu/closed-menu/closed-menu-categories.png",
  REWARD_CENTER_CLOSED_MENU_ARROW: "menu/closed-menu/closed-menu-arrow.png",
  REWARD_CENTER_OPENED_MENU_BACKGROUND: "menu/opened-menu/opened-menu-frame.png",
  REWARD_CENTER_OPENED_MENU_TITLE: "menu/opened-menu/opened-menu-title.png",
  REWARD_CENTER_OPENED_MENU_ARROW: "menu/opened-menu/opened-menu-arrow.png",
  REWARD_CENTER_OPENED_MENU_BUTTON_DEFAULT: "menu/opened-menu/opened-menu-default-button.png",
  REWARD_CENTER_OPENED_MENU_BUTTON_ACTIVE: "menu/opened-menu/opened-menu-active-button.png",
  REWARD_CENTER_OPENED_MENU_BUTTON_DISABLED: "menu/opened-menu/opened-menu-disabled-button.png",
  REWARD_CENTER_MENU_TABS_LOYALTY_POINTS: "menu/tabs/loyalty-points.png",
  REWARD_CENTER_MENU_TABS_LOYALTY_POINTS_SELECTED: "menu/tabs/loyalty-points-selected.png",
  REWARD_CENTER_MENU_TABS_MY_HISTORY: "menu/tabs/my-history.png",
  REWARD_CENTER_MENU_TABS_MY_HISTORY_SELECTED: "menu/tabs/my-history-selected.png",
  REWARD_CENTER_MENU_TABS_MY_PROFILE: "menu/tabs/my-profile.png",
  REWARD_CENTER_MENU_TABS_MY_PROFILE_SELECTED: "menu/tabs/my-profile-selected.png",
  REWARD_CENTER_MENU_UNDERLINE: "menu/tabs/underline.png",
  REWARD_CENTER_MENU_HISTORY_BANNER: "menu/details/history-banner.png",
  REWARD_CENTER_PURCHASE_SUCCESS: "offer-details/purchase-success.png",
  REWARD_CENTER_OFFER_DETAILS_ARROW: "offer-details/offer-details-arrow.png",
  REWARD_CENTER_PROFILE_FRAME_CARD: "profile/profile-frame-card.png",
  REWARD_CENTER_PROFILE_LINK_BUTTON: "profile/link-button.png",
  REWARD_CENTER_COINS_ICON: "offer-details/coins-icon.png",
  REWARD_CENTER_COINS_ICON_DISABLED: "offer-details/coins-icon-disabled.png",
  REWARD_CENTER_LOYALTY_ICON: "offer-details/loyalty-icon.png",
  REWARD_CENTER_LOYALTY_ICON_DISABLED: "offer-details/loyalty-icon-disabled.png",
  REWARD_CENTER_OFFER_DETAILS_PURPLE_BAR: "offer-details/purple-bar.png",
  REWARD_CENTER_ARROW_BACK_BUTTON: "main-screen/background/arrow-back-button.png",

  /**
   * Social asset keys
   */
  SOCIAL_FRIENDS_MORE_COINS_TITLE: 'title-more-friends.png',
  SOCIAL_FRIENDS_CHARACTERS_IMAGE: 'characters.png',
  SOCIAL_FRIENDS_UNDER_MAINTENANCE: 'under-maintenance.png',
  SOCIAL_FRIENDS_COMING_SOON: 'coming-soon.png',
  SOCIAL_MESSAGES_DIVIDER: 'message-divider.png',
  SOCIAL_LIMIT_REACHED_IMAGE: 'limit-reached.png'
};