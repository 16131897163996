import PurchaseFlow from './PurchaseFlow';
import { connect } from '../../node_modules/react-redux';
import { addNotif as _addNotif } from '../../state/actions/notification';
import { setCardNickname as _setCardNickname, setPrePurchaseRoute as _setPrePurchaseRoute, setPromoCode as _setPromoCode, setPrePurchaseRouteParams as _setPrePurchaseRouteParams, isOutOfCoinsPurchase as _isOutOfCoinsPurchase, isLTOPurchase as _isLTOPurchase, setFormUrl as _setFormUrl, setAccessCode as _setAccessCode, setTransactionId as _setTransactionId, setPurchaseProfile as _setPurchaseProfile } from '../../state/actions/prePurchase';
import { initiatePurchase } from '../../state/actions/purchase';
import { showCustomModal as _showCustomModal } from '../../state/actions/app';
import { clearLTOOffer as _clearLTOOffer } from '../../state/slices/consumables/consumables';

var mapStateToProps = function mapStateToProps(state) {
  return {
    shopList: state.shopList,
    outOfCoins: state.consumables.outOfCoins,
    ltoOffer: state.consumables.ltoOffer,
    ltoPurchase: state.prePurchase.ltoPurchase,
    outOfCoinsPurchase: state.prePurchase.outOfCoinsPurchase,
    paymentProvider: state.shopList.paymentProvider,
    promoLink: state.pendingMessages.find(function (pendingMessage) {
      return pendingMessage.type === 'PROMO_LINK';
    }),
    transactionUUID: state.purchase.transaction_uuid,
    gameInfo: state.gameInfo,
    target: state.app.target,
    formUrl: state.prePurchase.formUrl,
    accessCode: state.prePurchase.accessCode,
    purchaseProfile: state.prePurchase.purchaseProfile,
    shouldShowCustomModal: state.app.showCustomModal,
    assetsUrls: state.app.assetsUrls,
    coin: state.coin,
    level: state.levelInfo.level,
    loyalty: state.loyalty,
    KYCResult: state.playerInfo.KYCResult
  };
};

var mapDispatchToProps = function mapDispatchToProps(dispatch) {
  return {
    setCardNickname: function setCardNickname(name) {
      dispatch(_setCardNickname(name));
    },
    setPrePurchaseRoute: function setPrePurchaseRoute(route) {
      dispatch(_setPrePurchaseRoute(route));
    },
    setPrePurchaseRouteParams: function setPrePurchaseRouteParams(params) {
      dispatch(_setPrePurchaseRouteParams(params));
    },
    setPromoCode: function setPromoCode(code) {
      dispatch(_setPromoCode(code));
    },
    addNotif: function addNotif(notif) {
      dispatch(_addNotif(notif));
    },
    isOutOfCoinsPurchase: function isOutOfCoinsPurchase(value) {
      dispatch(_isOutOfCoinsPurchase(value));
    },
    isLTOPurchase: function isLTOPurchase(value) {
      dispatch(_isLTOPurchase(value));
    },
    clearLTOOffer: function clearLTOOffer() {
      dispatch(_clearLTOOffer());
    },
    dismissPurchase: function dismissPurchase() {
      dispatch(initiatePurchase(false));
    },
    setFormUrl: function setFormUrl(value) {
      dispatch(_setFormUrl(value));
    },
    setAccessCode: function setAccessCode(value) {
      dispatch(_setAccessCode(value));
    },
    setTransactionId: function setTransactionId(value) {
      dispatch(_setTransactionId(value));
    },
    showCustomModal: function showCustomModal(value) {
      dispatch(_showCustomModal(value));
    },
    setPurchaseProfile: function setPurchaseProfile(value) {
      dispatch(_setPurchaseProfile(value));
    }
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(PurchaseFlow);