export default {
  type: 'object',
  properties: {
    0: {
      type: 'object',
      properties: {
        assetPackageId: {
          type: 'string'
        },
        current_reward_day: {
          type: 'integer'
        },
        current_reward_day_index: {
          type: 'integer'
        },
        daily_bonus_currency: {
          type: 'string'
        },
        login_streak_bonus: {
          type: 'integer'
        },
        login_streak_day: {
          type: 'integer'
        },
        player_tier: {
          type: 'string'
        },
        prize_index: {
          type: 'integer'
        },
        wheel_hero: {
          type: 'boolean'
        },
        display_summary_streak_coins: {
          type: 'string'
        },
        display_summary_wheel_reward: {
          type: 'string'
        },
        display_total_coins_after_tier_bonus: {
          type: 'string'
        },
        display_total_loyalty: {
          type: 'string'
        },
        login_streak_coins_list: {
          type: 'object',
          patternProperties: {
            '[0-1000]+': {
              type: 'object',
              properties: {
                display_amount: {
                  type: 'string'
                }
              },
              required: ['display_amount']
            }
          },
          minProperties: 10,
          maxProperties: 10
        },
        login_streak_loyalty_list: {
          type: 'object',
          patternProperties: {
            '[0-1000]+': {
              type: 'object',
              properties: {
                amount: {
                  type: 'string'
                },
                display_amount: {
                  type: 'string'
                }
              },
              required: ['amount', 'display_amount']
            }
          },
          minProperties: 10,
          maxProperties: 10
        },
        member_tiers: {
          type: 'object',
          patternProperties: {
            '[0-1000]+': {
              type: 'object',
              properties: {
                daily_bonus_boost_percentage: {
                  type: 'integer'
                },
                points_required: {
                  type: 'integer'
                },
                tier_name: {
                  type: 'string'
                }
              },
              required: ['daily_bonus_boost_percentage', 'points_required', 'tier_name']
            }
          },
          minProperties: 1
        },
        spinner_items: {
          type: 'object',
          patternProperties: {
            '[0-1000]+': {
              type: 'object',
              properties: {
                0: {
                  type: 'string'
                },
                2: {
                  type: 'integer'
                },
                3: {
                  type: 'string'
                },
                4: {
                  type: 'string'
                }
              },
              required: ['0', '2', '3', '4']
            }
          },
          minProperties: 16,
          maxProperties: 16
        }
      },
      required: ['assetPackageId', 'current_reward_day', 'current_reward_day_index', 'daily_bonus_currency', 'login_streak_bonus', 'login_streak_day', 'player_tier', 'prize_index', 'wheel_hero', 'login_streak_coins_list', 'login_streak_loyalty_list', 'member_tiers', 'spinner_items', 'display_summary_streak_coins', 'display_summary_wheel_reward', 'display_total_coins_after_tier_bonus', 'display_total_loyalty']
    }
  }
};