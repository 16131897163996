import PurchaseSucceeded from './PurchaseSucceeded';
import { connect } from '../../node_modules/react-redux';
import { showVipAccessModal as _showVipAccessModal } from '../../state/slices/consumables/consumables';
import { gamesApi } from '../../state/query/games';

var mapStateToProps = function mapStateToProps(_ref) {
  var purchaseType = _ref.prePurchase.purchaseType;
  return {
    purchaseType: purchaseType
  };
};

var mapDispatchToProps = function mapDispatchToProps(dispatch) {
  return {
    showVipAccessModal: function showVipAccessModal(value) {
      dispatch(_showVipAccessModal(value));
    },
    getGameRooms: function getGameRooms(ignoreCache) {
      return dispatch(gamesApi.endpoints.getGameRooms.initiate(ignoreCache)).unwrap();
    }
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(PurchaseSucceeded);