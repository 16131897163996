import React, { Component } from 'react';
import { Alert } from 'react-bootstrap';
import SToast from 'shared/components/Toast';

export default class Toast extends Component {
  state = {};

  _renderColor = str => {
    const colorReg = new RegExp(/\{([^*]+)\}([a-zA-Z\d]*)/);
    const s = str.split(colorReg);
    return (
      <span
        key={s[2]}
        style={{
          fontWeight: '600',
          color: s[1],
        }}
      >
        {s[2]}
      </span>
    );
  };

  _renderBold = str => {
    const s = str.replace(/\*/g, '');
    return (
      <span
        key={s}
        style={{
          fontWeight: '600',
        }}
      >
        {s}
      </span>
    );
  };

  _sharedWillUpdate = (nextProps, nextState) => {
    if (this.state.visible != nextState.visible) {
      this.setState({ visible: nextState.visible });
    }
  };

  render() {
    return (
      <SToast
        {...this.props}
        renderColor={this._renderColor}
        renderBold={this._renderBold}
        render={args => {
          const { title, transformedMessage, expand, dismissHandler } = args;
          const className = `Toast ${expand ? 'show' : 'hide'}`;
          return (
            <div className={className}>
              <Alert dismissible onClose={dismissHandler}>
                <img src={require(asset`images/Toast_Assets/Background@3x.png`)} />
                {title && <h4>{title}</h4>}
                {transformedMessage}
              </Alert>
            </div>
          );
        }}
      />
    );
  }
}
