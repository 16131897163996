import _toConsumableArray from "/var/jenkins/workspace/Web Clients and Images - Production/Code/WEB CLIENT - GTP 2.0 Deploy/platforms/web/node_modules/babel-preset-react-app/node_modules/@babel/runtime/helpers/esm/toConsumableArray";
import _defineProperty from "/var/jenkins/workspace/Web Clients and Images - Production/Code/WEB CLIENT - GTP 2.0 Deploy/platforms/web/node_modules/babel-preset-react-app/node_modules/@babel/runtime/helpers/esm/defineProperty";

function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); enumerableOnly && (symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; })), keys.push.apply(keys, symbols); } return keys; }

function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = null != arguments[i] ? arguments[i] : {}; i % 2 ? ownKeys(Object(source), !0).forEach(function (key) { _defineProperty(target, key, source[key]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)) : ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } return target; }

import { DailyBonusResponseKeys, SpinnerItemKeys } from '../../../api/Constants';
import transformObjectArrayResponse from '../../../utils/transformObjectArrayResponse';
import transformObjectResponseKeys from '../../../utils/transformObjectResponseKeys';
export var getDailyWheelDataAdapter = function getDailyWheelDataAdapter(callResponse) {
  var _callResponse$LOGIN_S, _bonusInfo$MEMBER_TIE, _bonusInfo$MEMBER_TIE2;

  callResponse = transformObjectResponseKeys(callResponse, DailyBonusResponseKeys);
  callResponse.SPINNER_ITEMS = transformObjectArrayResponse(callResponse.SPINNER_ITEMS).map(function (item) {
    return transformObjectResponseKeys(item, SpinnerItemKeys);
  });
  callResponse.RESPIN_COST = parseInt(callResponse.RESPIN_COST);
  callResponse.LOGIN_STREAK_COINS_LIST = transformObjectArrayResponse(callResponse.LOGIN_STREAK_COINS_LIST);
  callResponse.LOGIN_STREAK_LOYALTY_LIST = transformObjectArrayResponse(callResponse.LOGIN_STREAK_LOYALTY_LIST);
  callResponse.LOGIN_STREAK_BONUS_LIST = callResponse.LOGIN_STREAK_COINS_LIST.map(function (item, i) {
    return {
      coins: item.display_amount,
      loyalty: callResponse.LOGIN_STREAK_LOYALTY_LIST[i].display_amount,
      day: parseInt(callResponse.LOGIN_STREAK_LOYALTY_LIST[i]['__sort_key'])
    };
  });
  callResponse.MEMBER_TIERS = transformObjectArrayResponse(callResponse.MEMBER_TIERS);

  if (((_callResponse$LOGIN_S = callResponse.LOGIN_STREAK_BONUS_LIST) === null || _callResponse$LOGIN_S === void 0 ? void 0 : _callResponse$LOGIN_S.findIndex(function (item) {
    return item.day === callResponse.CURRENT_REWARD_DAY;
  })) < 0) {
    return false;
  }

  var bonusInfo = _objectSpread({}, callResponse);

  var spinnerItems = _toConsumableArray((bonusInfo === null || bonusInfo === void 0 ? void 0 : bonusInfo.SPINNER_ITEMS) || []);

  var currentTierBonus = ((_bonusInfo$MEMBER_TIE = bonusInfo.MEMBER_TIERS) === null || _bonusInfo$MEMBER_TIE === void 0 ? void 0 : (_bonusInfo$MEMBER_TIE2 = _bonusInfo$MEMBER_TIE.find(function (tier) {
    var _bonusInfo$CURRENT_ME, _tier$tier_name;

    return ((_bonusInfo$CURRENT_ME = bonusInfo.CURRENT_MEMBER_TIER) === null || _bonusInfo$CURRENT_ME === void 0 ? void 0 : _bonusInfo$CURRENT_ME.toLowerCase()) === ((_tier$tier_name = tier.tier_name) === null || _tier$tier_name === void 0 ? void 0 : _tier$tier_name.toLowerCase());
  })) === null || _bonusInfo$MEMBER_TIE2 === void 0 ? void 0 : _bonusInfo$MEMBER_TIE2.daily_bonus_boost_percentage) || null;
  var totalLoyalty = bonusInfo.DISPLAY_TOTAL_LOYALTY,
      totalCoinsAfterTierBonus = bonusInfo.DISPLAY_TOTAL_COINS_AFTER_TIER_BONUS,
      dailyBonus = bonusInfo.DISPLAY_DAY_STREAK_COINS,
      wheelBonus = bonusInfo.DISPLAY_WHEEL_BONUS;
  return {
    bonusInfo: bonusInfo,
    currentTierBonus: currentTierBonus,
    wheelBonus: wheelBonus,
    dailyBonus: dailyBonus,
    totalLoyalty: totalLoyalty,
    totalCoinsAfterTierBonus: totalCoinsAfterTierBonus,
    spinnerItems: spinnerItems,
    showTotalCoins: totalCoinsAfterTierBonus && totalCoinsAfterTierBonus !== '0' ? true : false,
    showTotalLoyalty: totalLoyalty && totalLoyalty !== '0' ? true : false
  };
};