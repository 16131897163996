import React from 'react';
import theme from 'shared/assets/style/theme';
import { TEXT_KEY, getText } from 'shared/utils/localization';
import styled from 'styled-components';

const MessageEmpty = () => {
  return (
    <Container>
      <MailImage src={require(asset`mail_icon_open.png`)} />
      <Title>{getText(TEXT_KEY.MESSAGES_DESCRIPTION_TITLE)}</Title>
      <Description>{getText(TEXT_KEY.MESSAGES_DESCRIPTION)}</Description>
    </Container>
  );
};

export default MessageEmpty;

const Container = styled.div`
  height: 100%;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
`;

const MailImage = styled.img`
  width: 10rem;
  height: 7rem;
  object-fit: contain;
  margin-bottom: 1rem;
`;

const Title = styled.h4`
  background: linear-gradient(
    ${theme.palette.common[0]},
    ${theme.palette.common[1]} 20%,
    ${theme.palette.common[32]} 100%
  );
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  font-size: 2.5rem;
  margin: 0;
`;

const Description = styled.p`
  color: ${theme.palette.common[27]};
  margin-top: 0.5rem;
  font-size: 1.5rem;
`;

