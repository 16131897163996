import styled from 'styled-components';
import theme from 'shared/assets/style/theme';
import ImageBW from '../../components/ImageBoundsWrapper';

const DefaultBackgroundImage = styled.img`
  position: absolute;
  top: 0px;
  left: 0px;
  z-index: -1;
  width: 100%;
  height: 100%;
`;

const SocialModalStyle = {
  alignItems: 'center',
  display: 'flex',
  height: '100%',
  justifyContent: 'center',
  padding: '0 0',
  width: '100%',
};

const ModalMainBodyStyle = {
  width: '700px',
};

const ModalContainer = styled.div`
  height: 40vh;
  margin-bottom: -1vw;
  margin-top: -1vw;
  width: 100%;
`;

const ModalContent = styled.div`
  align-items: center;
  display: flex;
  flex-direction: column;
  height: 100%;
  justify-content: space-evenly;
  padding: 12% 0 6% 0;
  width: 100%;
`;

const HeaderContainer = styled(ImageBW)`
  height: 18%;
  left: 0;
  object-fit: contain;
  top: 0;
  width: 100%;
  z-index: 11;
  align-items: center;
  display: flex;
  justify-content: center;
`;

const HeaderContnet = styled.div`
  align-items: center;
  display: flex;
  justify-content: space-between;
`;

const HeaderItem = styled.div`
  align-items: center;
  display: flex;
  flex: 1;
  justify-content: ${({ justify }) => justify || 'center'};
`;

const Title = styled.p`
  color: ${theme.palette.common[1]};
  font-size: 1.9rem;
  font-weight: 600;
  margin-top: 0.5rem;
  letter-spacing: 1px;
  text-shadow: 0px 1px 2px rgba(0, 0, 0, 0.5);
`;

const Message = styled.p`
  color: #fff;
  font-size: 1.2rem;
  font-weight: 500;
  padding-bottom: 1rem;
`;

const NeedsHelpText = styled.p`
  font-size: 15px;
  font-weight: 600;
  color: #fff;
  top: 1%;
  padding-right: 15px;
  padding-top: 10px;
  cursor: pointer;
`;

export {
  DefaultBackgroundImage,
  HeaderContainer,
  HeaderItem,
  Message,
  ModalContainer,
  ModalContent,
  ModalMainBodyStyle,
  SocialModalStyle,
  Title,
  HeaderContnet,
  NeedsHelpText,
};

