import { createApi } from '@reduxjs/toolkit/query/react';
import { axiosBaseQuery } from '../../api/services/rtkQuery/axiosBaseQuery';
import { getGameRoomsQuery, getInitGameQuery } from './queries';
export var gamesApi = createApi({
  reducerPath: 'gamesQuery',
  baseQuery: axiosBaseQuery(),
  tagTypes: ['GetGameRooms'],
  endpoints: function endpoints(builder) {
    return {
      getGameRooms: builder.query(getGameRoomsQuery),
      getInitGame: builder.query(getInitGameQuery)
    };
  }
});
var useGetGameRoomsQuery = gamesApi.useGetGameRoomsQuery,
    useGetInitGameQuery = gamesApi.useGetInitGameQuery;
export { useGetGameRoomsQuery, useGetInitGameQuery };
var _gamesApi$endpoints = gamesApi.endpoints,
    getGameRooms = _gamesApi$endpoints.getGameRooms,
    getInitGame = _gamesApi$endpoints.getInitGame,
    gamesReducerPath = gamesApi.reducerPath,
    gamesQueryReducer = gamesApi.reducer,
    gamesMiddleware = gamesApi.middleware;
export { gamesReducerPath, gamesQueryReducer, gamesMiddleware, getGameRooms, getInitGame };