import React, { Component } from 'react';
import SMessageItem from 'shared/components/MessageItem';
import { messageTypes, format } from 'shared/utils/messageTypes';
import CdnPath from 'shared/utils/cdnPath';
import theme from 'shared/assets/style/theme';
import ImageBW from '../../components/ImageBoundsWrapper';
import Utils from 'shared/utils';
import Button from '../Button/Button';
import { getText, TEXT_KEY } from 'shared/utils/localization';
import { messagePinkButtonStroke, messageOrangeButtonStroke } from 'shared/components/MessageItem/utils';
import generateRender from 'shared/utils/generateRender';
import ThemeContext from 'shared/context/ThemeContext';

/* const BUTTON_TYPES = {
  COLLECT: {
    background: require(asset`Orange_Button@3x.png`),
    text: getText(TEXT_KEY.COLLECT_BUTTON),
  },
  REDEEM: { background: require(asset`Pink_Button@3x.png`), text: getText(TEXT_KEY.REDEEM_BUTTON) },
  PLAY: { background: require(asset`Pink_Button@3x.png`), text: getText(TEXT_KEY.PLAY_BUTTON) },
  BUY: { background: require(asset`Pink_Button@3x.png`), text: getText(TEXT_KEY.BUY_BUTTON) },
  SNEAK_PEEK: { background: require(asset`Pink_Button@3x.png`), text: getText(TEXT_KEY.SNEAK_PEEK_BUTTON) },
}; */

export default class MessageItem extends Component {
  BUTTON_TYPES = {
    COLLECT: {
      background: require(asset`Orange_Button@3x.png`),
      text: getText(TEXT_KEY.COLLECT_BUTTON),
      textStroke: messageOrangeButtonStroke,
    },
    REDEEM: {
      background: require(asset`Pink_Button@3x.png`),
      text: getText(TEXT_KEY.REDEEM_BUTTON),
      textStroke: messagePinkButtonStroke,
    },
    PLAY: {
      background: require(asset`Pink_Button@3x.png`),
      text: getText(TEXT_KEY.PLAY_BUTTON),
      textStroke: messagePinkButtonStroke,
    },
    BUY: {
      background: require(asset`Pink_Button@3x.png`),
      text: getText(TEXT_KEY.BUY_BUTTON),
      textStroke: messagePinkButtonStroke,
    },
    SNEAK_PEEK: {
      background: require(asset`Pink_Button@3x.png`),
      text: getText(TEXT_KEY.SNEAK_PEEK_BUTTON),
      textStroke: messagePinkButtonStroke,
    },
  };

  MESSAGE_TYPES = {
    COINS: {
      background: require(asset`Coins_Default@3x.png`),
      icon: require(asset`Coins_Mail_Icon@3x.png`),
      image: require(asset`images/Top_Bar_Assets/Coin_Icon@3x.png`),
      imageIBW: require(asset`Coin_Icon_Inbox.ibw`),
      button: this.BUTTON_TYPES.COLLECT,
      type: messageTypes.COINS,
    },
    LOYALTY: {
      background: require(asset`Message_Inbox_Loyalty_Promo@3x.png`),
      icon: require(asset`Loyalty_Mail_Icon@3x.png`),
      image: require(asset`loyalty-icon@3x.png`),
      imageIBW: require(asset`loyalty-icon-inbox.ibw`),
      button: this.BUTTON_TYPES.COLLECT,
      type: messageTypes.LOYALTY,
    },
    SNEAK_PEEK: {
      background: require(asset`images/SneakPeek/sneak_peek_background@3x.png`),
      icon: require(asset`images/SneakPeek/icon_sneak_peek@3x.png`),
      button: this.BUTTON_TYPES.SNEAK_PEEK,
      type: messageTypes.SNEAK_PEEK,
    },
    VIP_PASS: {
      background: require(asset`images/VIP_Message_Assets/VIP_Message_Background@3x.png`),
      icon: require(asset`images/VIP_Message_Assets/VIP_Message_Icon@3x.png`),
      button: this.BUTTON_TYPES.REDEEM,
      type: messageTypes.VIP_PASS,
    },
    PROMO: {
      background: '',
      icon: require(asset`Coins_Mail_Icon@3x.png`),
      type: messageTypes.PROMO,
    },
    OFFER: {
      background: require(asset`Coins_Default@3x.png`),
      icon: require(asset`Coins_Mail_Icon@3x.png`),
      button: this.BUTTON_TYPES.BUY,
      type: messageTypes.OFFER,
    },
    GAME: {
      background: '',
      icon: require(asset`Coins_Mail_Icon@3x.png`),
      button: this.BUTTON_TYPES.PLAY,
      type: messageTypes.GAME,
    },
  };

  _checkMessageType = (action, loyalty_amount, coin_amount) => {
    if (action.search('gameid:') !== -1) {
      return this.MESSAGE_TYPES.GAME;
    }
    if (action.search('vippass:') !== -1) {
      return this.MESSAGE_TYPES.VIP_PASS;
    }
    if (action.search('offer:') !== -1) {
      return this.MESSAGE_TYPES.OFFER;
    }
    if (action.search('sneakPeek:') !== -1) {
      return this.MESSAGE_TYPES.SNEAK_PEEK;
    }
    if (loyalty_amount > 0) {
      return this.MESSAGE_TYPES.LOYALTY;
    }
    if (coin_amount > 0) {
      return this.MESSAGE_TYPES.COINS;
    }
    return this.MESSAGE_TYPES.PROMO;
  };

  _checkBackgroundImage = (message, background) => {
    if (
      message.type === messageTypes.VIP_PASS ||
      message.type === messageTypes.SNEAK_PEEK
    ) {
      return message.background;
    }
    if (background != null && background !== '') {
      return this.props.imagesCdnBaseUrl + '/inbox_messages/' + background;
    } else {
      return message.background != null ? message.background : '';
    }
  };

  _checkIconImage = (msgTypeObject, icon, message) => {
    switch (msgTypeObject.type) {
      case messageTypes.VIP_PASS:
        return msgTypeObject.icon;
      case messageTypes.SNEAK_PEEK:
        const gameListArray = message.action && message.action.split(':')[1].split(',');
        if (gameListArray.length === 1 && gameListArray[0]) {
          return CdnPath.SLOT_ICON_WEB + gameListArray[0] + '.png';
        } else {
          return msgTypeObject.icon;
        }
      default:
        if (icon != null && icon !== '') {
          return this.props.imagesCdnBaseUrl + '/inbox_messages/' + icon;
        } else {
          return msgTypeObject.icon != null ? msgTypeObject.icon : '';
        }
    }
  };

  _checkSubtitle = (message, checkingMessage) => {
    switch (checkingMessage.type) {
      case messageTypes.LOYALTY:
        return Utils.truncateFormat(message.loyalty_amount);
      case messageTypes.COINS:
        return Utils.truncateFormat(message.coin_amount);
      case messageTypes.VIP_PASS:
        let dynamicText = ``;
        let totalSeconds = parseInt(message.action.split(':')[1]);
        let hours = Math.floor(totalSeconds / 3600);
        if (hours > 0) dynamicText += ` ${hours} ${getText(hours > 1 ? TEXT_KEY.HOURS : TEXT_KEY.HOUR)}`;
        totalSeconds %= 3600;
        let minutes = Math.floor(totalSeconds / 60);
        if (minutes > 0) dynamicText += ` ${minutes} ${getText(TEXT_KEY.MINUTES)}`;
        let seconds = totalSeconds % 60;
        if (seconds > 0) dynamicText += ` ${seconds} ${getText(TEXT_KEY.SECONDS)}`;
        let vipPassSubtitle = `${getText(TEXT_KEY.VIP_PASS_SUBTITLE_REDEEM)}${dynamicText}${getText(
          TEXT_KEY.VIP_PASS_SUBTITLE_EXCLUSIVE
        )}${getText(TEXT_KEY.VIP)}${getText(TEXT_KEY.VIP_PASS_SUBTITLE_ACCESS)}`;
        return vipPassSubtitle;
      case messageTypes.OFFER:
        let subtitle = `${getText(TEXT_KEY.OFFER_TYPE_SUBTITLE_GET)}${format(message.coins)}${getText(
          TEXT_KEY.OFFER_TYPE_SUBTITLE_COINS
        )}${getText(TEXT_KEY.CURRENCY_SIGN)}${message.price}!`;
        return subtitle;
      case messageTypes.SNEAK_PEEK:
        return message.subject;
      default:
        return getText(TEXT_KEY.FIND_OUT_MORE);
    }
  };

  _checkActionHandler = (message, msgTypeObject, dynamicActionHandler, collectRewardHandler) => {
    if (msgTypeObject.type === messageTypes.GAME) {
      dynamicActionHandler(message.action);
    } else {
      collectRewardHandler(message, msgTypeObject.type, Utils.getPlatform());
    }
  };

  render() {
    const closeButton = generateRender({
      admiral: require(asset`CloseButton@3x.png`),
      default: require(asset`Close_Button@3x.png`),
    });
    const themeContext = this.context;
    return (
      <SMessageItem
        navigation={this.props.navigation}
        render={args => {
          const { deleteMessage, dynamicActionHandler, collectRewardHandler } = args;
          const { message, messageDetailsHandle } = this.props;
          const { message_id, title, subject, coin_amount, loyalty_amount, action, background, icon, deleted } =
            message;
          const type = this._checkMessageType(action, loyalty_amount, coin_amount);
          const backgroundImage = this._checkBackgroundImage(type, background);
          const iconImage = this._checkIconImage(type, icon, message);

          return (
            <div
              className={`message-item ${deleted ? 'DeletedAnimation' : ''}`}
              style={{
                position: 'relative',
                height: '100px',
              }}
            >
              <img
                style={{
                  position: 'absolute',
                  top: '-7%',
                  left: 0,
                  width: '100%',
                  height: '127%',
                }}
                src={require(asset`Mail_Message_Default@3x.png`)}
                alt=""
              />
              {backgroundImage && (
                <>
                  <img
                    style={{
                      position: 'absolute',
                      top: 2,
                      left: 14,
                      width: '96.4%',
                      height: '98%',
                      borderRadius: '0.4em',
                    }}
                    src={backgroundImage}
                    alt=""
                  />
                  <div
                    style={{
                      position: 'absolute',
                      top: 2,
                      left: 14,
                      width: '96.4%',
                      height: '98%',
                      borderRadius: '0.4em',
                      backgroundColor: theme.palette.common[35],
                    }}
                  />
                </>
              )}
              <ImageBW
                source={iconImage}
                ibw={require(asset`Coins_Mail_Icon.ibw`)}
                style={{
                  height: 'fit-content',
                  width: 'fit-content',
                  left: 10,
                  right: 'auto',
                  top: 3,
                }}
                innerStyle={{
                  cursor: 'pointer',
                  width: '100%',
                }}
                alt="Close"
              />
              <div
                style={{
                  margin: '5px',
                  marginLeft: '105px',
                  zIndex: 3,
                  position: 'absolute',
                  width: '60%',
                  textOverflow: 'ellipsis',
                  overflow: 'hidden',
                }}
              >
                <h4 className="textShaddowOnLightSurface" style={themeContext.MessageItem.Title}>
                  {title ? title : subject}
                </h4>
                <div
                  style={{
                    display: 'flex',
                    alignItems: 'center',
                    marginTop: '8px',
                  }}
                >
                  {type.image && (
                    <ImageBW
                      source={type.image}
                      ibw={type.imageIBW}
                      style={{
                        height: 24,
                        width: 24,
                        marginRight: 2,
                        objectFit: 'contain',
                      }}
                    />
                  )}
                  <h4
                    className="textShaddowOnLightSurface"
                    style={{
                      fontSize:
                        type.type !== messageTypes.COINS && type.type !== messageTypes.LOYALTY ? '16px' : '22px',
                      ...themeContext.MessageItem.Subtitle,
                    }}
                  >
                    {this._checkSubtitle(message, type)}
                  </h4>
                </div>
              </div>
              {type.button ? (
                <Button
                  imageSource={type.button.background}
                  containerStyle={{
                    position: 'absolute',
                    right: '1.5rem',
                    top: '2rem',
                    zIndex: 3,
                    cursor: 'pointer',
                  }}
                  textStroke={type.button.textStroke}
                  imageStyle={{ width: '100%' }}
                  label={type.button.text}
                  onClick={() => this._checkActionHandler(message, type, dynamicActionHandler, collectRewardHandler)}
                />
              ) : (
                <div
                  style={{
                    position: 'absolute',
                    left: 'auto',
                    right: '80px',
                    top: '23px',
                    zIndex: 3,
                    cursor: 'pointer',
                    width: 31,
                    height: 47,
                  }}
                  onClick={() => {
                    messageDetailsHandle(message_id);
                  }}
                >
                  <ImageBW
                    source={require(asset`Right_Arrow@3x.png`)}
                    ibw={require(asset`Right_Arrow.ibw`)}
                    style={{
                      position: 'relative',
                      width: 'fit-content',
                      top: '3px',
                    }}
                    innerStyle={{}}
                    alt="Close"
                  />
                </div>
              )}

              <div
                style={{
                  position: 'absolute',
                  height: '30px',
                  width: '30px',
                  left: '0',
                  top: '-6px',
                  zIndex: 3,
                }}
                onClick={() => deleteMessage(message_id)}
              >
                <ImageBW
                  source={closeButton}
                  ibw={require(asset`CloseButton.ibw`)}
                  manualAssetScale={3}
                  style={{
                    objectFit: 'contain',
                    height: '100%',
                    width: '100%',
                  }}
                  innerStyle={{
                    cursor: 'pointer',
                  }}
                  alt="Close"
                />
              </div>
            </div>
          );
        }}
      />
    );
  }
}

MessageItem.contextType = ThemeContext;

