import React from 'react';
import {
  OfferButtonWrapper,
  OfferDescWrapper,
  OfferPropertySelectorWrapper,
  OfferSubTitleHolder,
  OfferTermsWrapper,
  OfferTitleWrapper,
  ScrollViewStyle,
  offerDetailStyles,
} from './styledComponents';
import ENVIRONMENT_VARIABLES from 'shared/utils/environmentVariables';
import PropertySelector from '../../../../../../components/PropertySelector';
import Button from '../../../../../../components/Button/Button';
import theme from 'shared/assets/style/theme';
import { TEXT_KEY, getText } from 'shared/utils/localization';
import { numberWithCommas } from 'shared/utils/number';
import { BUTTON_STROKES } from 'shared/utils/buttonTextStrokes';

const OfferDetailsDescription = props => {
  const {
    propertyList,
    rewardname,
    subtitle,
    rdesc,
    pointsneeded,
    termsText,
    termsUrl,
    handlePurchase,
    isPurchaseButtonDisabled,
    onDisabledButtonClick,
    changeOfferProperty,
    rewardCenterAssets,
  } = props;
  const displayPropertySelector = propertyList && ENVIRONMENT_VARIABLES.SHOW_PROPERTY_SELECTOR === 'true';

  return (
    <ScrollViewStyle>
      <OfferTitleWrapper>
        <div className="rewardCenterGradientText OfferDetailName" data-label={rewardname}>
          {rewardname}
        </div>
      </OfferTitleWrapper>
      <OfferSubTitleHolder>
        <div className="OfferDetailSubtitle">{subtitle}</div>
      </OfferSubTitleHolder>
      <div className="offerDetailsActionContainer">
        {displayPropertySelector && (
          <OfferPropertySelectorWrapper>
            <PropertySelector
              onChangeDOB={e => changeOfferProperty(e.value)}
              selectedPropertyId={0}
              styles={{ color: theme.palette.common[1] }}
              customOptions={propertyList}
              hidePlaceHolder
            />
          </OfferPropertySelectorWrapper>
        )}
        <OfferButtonWrapper>
          <Button
            imageSource={
              isPurchaseButtonDisabled
                ? require(asset`RoundedRect_Disabled.png`)
                : require(asset`RoundedRect_Primary.png`)
            }
            imageStyle={offerDetailStyles.offerButtonImageStyle}
            containerStyle={offerDetailStyles.offerButtonContainerStyle}
            offerButtonWrapperStyle={offerDetailStyles.offerButtonWrapperStyle}
            onClick={isPurchaseButtonDisabled ? onDisabledButtonClick : handlePurchase}
            buttonIconStyle={{ width: '4em' }}
            buttonIcon={
              isPurchaseButtonDisabled
                ? rewardCenterAssets.REWARD_CENTER_LOYALTY_ICON_DISABLED
                : rewardCenterAssets.REWARD_CENTER_LOYALTY_ICON
            }
            textStroke={isPurchaseButtonDisabled ? BUTTON_STROKES.DISABLED : BUTTON_STROKES.PRIMARY}
            label={numberWithCommas(pointsneeded)}
          />
        </OfferButtonWrapper>
      </div>
      <OfferDescWrapper>
        <div className="OfferDetailDescription">{rdesc}</div>
      </OfferDescWrapper>
      <OfferTermsWrapper>
        <div className="OfferDetailTermTitle">{getText(TEXT_KEY.PURCHASE_AND_USAGE_LIMITATIONS)}</div>
        <div className="OfferDetailTerm">
          {termsText}
          <a href={termsUrl} target="_blank" rel="noopener noreferrer">
            {termsUrl}
          </a>
        </div>
      </OfferTermsWrapper>
    </ScrollViewStyle>
  );
};

export default OfferDetailsDescription;

