export var TEXT_KEY = {
  BONUS_LINK_MESSAGE_DESCRIPTION: 'BONUS_LINK_MESSAGE_DESCRIPTION',
  BONUS_LINK_MESSAGE_BUTTON: 'BONUS_LINK_MESSAGE_BUTTON',
  BONUS_LINK_MESSAGE_INVALID_TITLE: 'BONUS_LINK_MESSAGE_INVALID_TITLE',
  BONUS_LINK_MESSAGE_INVALID_DESCRIPTION: 'BONUS_LINK_MESSAGE_INVALID_DESCRIPTION',
  MESSAGES_TITLE: 'MESSAGES_TITLE',
  MESSAGES_DESCRIPTION_TITLE: 'MESSAGES_DESCRIPTION_TITLE',
  MESSAGES_DESCRIPTION: 'MESSAGES_DESCRIPTION',
  MESSAGES_FOOTER: 'MESSAGES_FOOTER',
  REGISTER_REWARD_CARD_TITLE: 'REGISTER_REWARD_CARD_TITLE',
  REGISTER_REWARD_CARD_DESCRIPTION: 'REGISTER_REWARD_CARD_DESCRIPTION',
  REGISTER_REWARD_CARD_DATE: 'REGISTER_REWARD_CARD_DATE',
  REGISTER_REWARD_CARD_BUTTON: 'REGISTER_REWARD_CARD_BUTTON',
  REGISTER_REWARD_CARD_SUCCESS: 'REGISTER_REWARD_CARD_SUCCESS',
  REGISTER_REWARD_CARD_ERROR_MESSAGE: 'REGISTER_REWARD_CARD_ERROR_MESSAGE',
  SHARE_EMAIL: 'SHARE_EMAIL',
  TRY_AGAIN: 'TRY_AGAIN',
  DECLINE: 'DECLINE',
  NEED_HELP: 'NEED_HELP',
  CONGRATULATIONS: 'CONGRATULATIONS',
  YOU_HAVE_EARNED: 'YOU_HAVE_EARNED',
  CHEAT_MENU: 'CHEAT_MENU',
  SIGN_OUT: 'SIGN_OUT',
  CONFIRM_LOG_OUT: 'CONFIRM_LOG_OUT',
  VERSION: 'VERSION',
  EDIT: 'EDIT',
  CHANGE_PASSWORD: 'CHANGE_PASSWORD',
  USE_STRONG_PASSWORD: 'USE_STRONG_PASSWORD',
  SUCCESS_RESPONSE: 'SUCCESS_RESPONSE',
  SOMETHING_WENT_WRONG: 'SOMETHING_WENT_WRONG',
  MESSAGE_SUCCESSFULLY_SENT: 'MESSAGE_SUCCESSFULLY_SENT',
  BACK_TO_YOU_SHORTLY: 'BACK_TO_YOU_SHORTLY',
  WE_WILL_GET_BACK_TO_YOU: 'WE_WILL_GET_BACK_TO_YOU',
  TRY_AGAIN_OR_CONTACT_SUPPORT: 'TRY_AGAIN_OR_CONTACT_SUPPORT',
  PLEASE_SUBMIT_A_REQUEST: 'PLEASE_SUBMIT_A_REQUEST',
  SUPPORT_PORTAL: 'SUPPORT_PORTAL',
  DONE: 'DONE',
  DETAILS_UPDATED: 'DETAILS_UPDATED',
  CONTACT_SUPPORT_FAILED: 'CONTACT_SUPPORT_FAILED',
  CONTACT_SUPPORT: 'CONTACT_SUPPORT',
  ACCOUNT: 'ACCOUNT',
  SOUND_EFFECTS: 'SOUND_EFFECTS',
  MUSIC: 'MUSIC',
  DELETE_ACCOUNT: 'DELETE_ACCOUNT',
  DELETE: 'DELETE',
  ENV_CHANGED: 'ENV_CHANGED',
  PASSWORDS_DONT_MATCH: 'PASSWORDS_DONT_MATCH',
  PASSWORD_SUCCESSFULLY_CHANGED: 'PASSWORD_SUCCESSFULLY_CHANGED',
  PLAYER_ID: 'PLAYER_ID',
  MEMBER_ID: 'MEMBER_ID',
  MY_USER_ID: 'MY_USER_ID',
  USER_AGENT: 'USER_AGENT',
  DEVICE: 'DEVICE',
  SYSTEM_VERSION: 'SYSTEM_VERSION',
  APP_VERSION: 'APP_VERSION',
  KYC_CHECK: 'KYC_CHECK',
  OOPS: 'OOPS',
  PURCHASE_AND_USAGE_LIMITATIONS: 'PURCHASE_AND_USAGE_LIMITATIONS',
  REWARD_CENTER_KYC_MESSAGE: 'REWARD_CENTER_KYC_MESSAGE',
  DAILY_BONUS_DAY_10: 'DAILY_BONUS_DAY_10',
  DAILY_BONUS: 'DAILY_BONUS',
  DONT_BREAK_YOUR_STREAK: 'DONT_BREAK_YOUR_STREAK',
  PLUS_SYMBOL: 'PLUS_SYMBOL',
  SPIN: 'SPIN',
  TIER_BONUS: 'TIER_BONUS',
  WHEEL_BONUS: 'WHEEL_BONUS',
  MAINTAIN_LOGIN: 'MAINTAIN_LOGIN',
  UNLOCK_TIER_BONUS: 'UNLOCK_TIER_BONUS',
  ARE_YOU_SURE: 'ARE_YOU_SURE',
  CLOSE_VIDEO_MODAL: 'CLOSE_VIDEO_MODAL',
  DEFAULT_ERROR_MESSAGE: 'DEFAULT_ERROR_MESSAGE',
  KEEP_WATCHING: 'KEEP_WATCHING',
  VIDEO_ERROR: 'VIDEO_ERROR',
  WILL_NOT_RECEIVE_COINS: 'WILL_NOT_RECEIVE_COINS',
  ALREADY_WATCHED_ALL: 'ALREADY_WATCHED_ALL',
  COME_BACK_IN: 'COME_BACK_IN',
  COME_BACK_LATER: 'COME_BACK_LATER',
  PLEASE_WAIT: 'PLEASE_WAIT',
  PROCESSING_REQUEST: 'PROCESSING_REQUEST',
  BETTER_REWARDS: 'BETTER_REWARDS',
  AND_BIGGER_BETS: 'AND_BIGGER_BETS',
  TERMS_AND_CONDITIONS: 'TERMS_AND_CONDITIONS',
  TERMS_AND_CONDITIONS_TITLE: 'TERMS_AND_CONDITIONS_TITLE',
  PRIVACY_POLICY: 'PRIVACY_POLICY',
  CONTINUE_BUTTON: 'CONTINUE_BUTTON',
  STAR_AGE_AGREEMENT: 'STAR_AGE_AGREEMENT',
  STAR_CONSENT_TO_MARKETING: 'STAR_CONSENT_TO_MARKETING',
  STAR_PRIVACY_COLLECTION_TITLE: 'STAR_PRIVACY_COLLECTION_TITLE',
  STAR_PRIVACY_COLLECTION_BODY: 'STAR_PRIVACY_COLLECTION_BODY',
  STAR_PRIVACY_POLICY_PART_ONE: 'STAR_PRIVACY_POLICY_PART_ONE',
  STAR_PRIVACY_POLICY_PART_TWO: 'STAR_PRIVACY_POLICY_PART_TWO',
  TERMS_UPDATE_SCREEN_TEXT: 'TERMS_UPDATE_SCREEN_TEXT',
  DO_YOU_ALLOW: 'DO_YOU_ALLOW',
  ACTIVITY_TRACKING_REQUEST: 'ACTIVITY_TRACKING_REQUEST',
  DATA_USAGE_DESCRIPTION: 'DATA_USAGE_DESCRIPTION',
  ASK_NOT_TO_TRACK: 'ASK_NOT_TO_TRACK',
  ALLOW: 'ALLOW',
  CONFIRMATION: 'CONFIRMATION',
  WONT_SAVE: 'WONT_SAVE',
  CANCEL: 'CANCEL',
  LEAVE: 'LEAVE',
  ADDRESS_MANUALLY: 'ADDRESS_MANUALLY',
  SEARCH_ADDRESSES: 'SEARCH_ADDRESSES',
  SELECT_ITEM: 'SELECT_ITEM',
  NO_MATCHES: 'NO_MATCHES',
  AVAILABLE_IN: 'AVAILABLE_IN',
  NOT_AVAILABLE: 'NOT_AVAILABLE',
  RESET_PASSWORD_TITLE: 'RESET_PASSWORD_TITLE',
  DECLINE_FORM: 'DECLINE_FORM',
  SUBMIT: 'SUBMIT',
  NEXT_STEP: 'NEXT_STEP',
  FROM: 'FROM',
  LEVEL: 'LEVEL',
  TAP_START: 'TAP_START',
  FTUE_TAP_FIRST_SPIN: 'FTUE_TAP_FIRST_SPIN',
  FTUE_LEVEL_UP_TO_GET: 'FTUE_LEVEL_UP_TO_GET',
  FTUE_BETTER_REWARDS: 'FTUE_BETTER_REWARDS',
  FTUE_BETTER_BIGGER_BETS: 'FTUE_BETTER_BIGGER_BETS',
  GAME_ADDED_TO_FAVS: 'GAME_ADDED_TO_FAVS',
  GO_BACK: 'GO_BACK',
  AGREE_TERMS: 'AGREE_TERMS',
  TERMS_AND_CONDITIONS_IAP: 'TERMS_AND_CONDITIONS_IAP',
  NEXT_LEVEL: 'NEXT_LEVEL',
  MAX: 'MAX',
  LEVEL_UP_MESSAGE: 'LEVEL_UP_MESSAGE',
  NEW_GAME: 'NEW_GAME',
  AUDIO_VIEW_TITTLE: 'AUDIO_VIEW_TITTLE',
  BUTTON_PLAY: 'BUTTON_PLAY',
  BUTTON_START: 'BUTTON_START',
  BUTTON_STOP: 'BUTTON_STOP',
  BUTTON_MODAL: 'BUTTON_MODAL',
  BUTTON_CONFIRM: 'BUTTON_CONFIRM',
  COLLECT_BUTTON: 'COLLECT_BUTTON',
  REDEEM_BUTTON: 'REDEEM_BUTTON',
  PLAY_BUTTON: 'PLAY_BUTTON',
  BUY_BUTTON: 'BUY_BUTTON',
  SNEAK_PEEK_BUTTON: 'SNEAK_PEEK_BUTTON',
  HOURS: 'HOURS',
  HOUR: 'HOUR',
  MINUTES: 'MINUTES',
  MINUTE: 'MINUTE',
  SECONDS: 'SECONDS',
  VIP_PASS_SUBTITLE_REDEEM: 'VIP_PASS_SUBTITLE_REDEEM',
  VIP_PASS_SUBTITLE_EXCLUSIVE: 'VIP_PASS_SUBTITLE_EXCLUSIVE',
  VIP_PASS_SUBTITLE_ACCESS: 'VIP_PASS_SUBTITLE_ACCESS',
  OFFER_TYPE_SUBTITLE_GET: 'OFFER_TYPE_SUBTITLE_GET',
  OFFER_TYPE_SUBTITLE_COINS: 'OFFER_TYPE_SUBTITLE_COINS',
  FIND_OUT_MORE: 'FIND_OUT_MORE',
  OK: 'OK',
  REWARD_INFO: 'REWARD_INFO',
  REWARD_COLLECTED: 'REWARD_COLLECTED',
  NO_EXPIRY_DATE: 'NO_EXPIRY_DATE',
  EXPIRES_ON: 'EXPIRES_ON',
  EMAIL_SENT_MESSAGE: 'EMAIL_SENT_MESSAGE',
  ISSUED_ON: 'ISSUED_ON',
  SEND_EMAIL: 'SEND_EMAIL',
  REMAINING_BALANCE: 'REMAINING_BALANCE',
  NO_OFFERS_REDEEMED_YET: 'NO_OFFERS_REDEEMED_YET',
  OFFER: 'OFFER',
  STATUS: 'STATUS',
  REDEMPTION_DATE: 'REDEMPTION_DATE',
  REMAINING: 'REMAINING',
  WELCOME: 'WELCOME',
  WELCOME_START: 'WELCOME_START',
  BUY: 'BUY',
  DEAL: 'DEAL',
  SALE: 'SALE',
  VIP_GAMES_TITLE: 'VIP_GAMES_TITLE',
  CHOOSE_PASS: 'CHOOSE_PASS',
  AGREE_TCS: 'AGREE_TCS',
  GAIN_INSTANT_ACCESS: 'GAIN_INSTANT_ACCESS',
  PLAY_50_GAMES: 'PLAY_50_GAMES',
  TIME_REMAINING: 'TIME_REMAINING',
  ALREADY_HAVE_VIP_ACCESS: 'ALREADY_HAVE_VIP_ACCESS',
  ROOM: 'ROOM',
  VIP: 'VIP',
  VIP_ACCESS_NEEDED: 'VIP_ACCESS_NEEDED',
  VIP_ACCESS_WAYS: 'VIP_ACCESS_WAYS',
  ALERT_KYC_SUCCESS: 'ALERT_KYC_SUCCESS',
  ALERT_KYC_ERROR: 'ALERT_KYC_ERROR',
  ALERT_DUP_ERROR: 'ALERT_DUP_ERROR',
  SUCCESS_NOTIFICATION_TITLE: 'SUCCESS_NOTIFICATION_TITLE',
  SUCCESS_NOTIFICATION_MESSAGE: 'SUCCESS_NOTIFICATION_MESSAGE',
  ACCOUNT_ALREADY_LINKED_TITLE: 'ACCOUNT_ALREADY_LINKED_TITLE',
  ACCOUNT_ALREADY_LINKED: 'ACCOUNT_ALREADY_LINKED',
  NOTIFICATION_MODAL_CANCEL: 'NOTIFICATION_MODAL_CANCEL',
  DETAILS_SHOULD_MATCH_DOC: 'DETAILS_SHOULD_MATCH_DOC',
  CLICK_HERE: 'CLICK_HERE',
  LOG_IN_AND_SKIP: 'LOG_IN_AND_SKIP',
  REDIRECTING_TO_STAR_CLUB: 'REDIRECTING_TO_STAR_CLUB',
  POLICY: 'POLICY',
  TERMS: 'TERMS',
  UPDATE_TERMS_ERROR: 'UPDATE_TERMS_ERROR',
  SIGNUP_TITLE: 'SIGNUP_TITLE',
  CANT_FIND_MEMBER_ID: 'CANT_FIND_MEMBER_ID',
  CHECK_WELCOME_EMAIL: 'CHECK_WELCOME_EMAIL',
  CONFIRM_TERMS_AGREE: 'CONFIRM_TERMS_AGREE',
  MARKETING_CONSENT: 'MARKETING_CONSENT',
  PRIVACY_POLICY_AGREE: 'PRIVACY_POLICY_AGREE',
  CONTACT_BUTTON: 'CONTACT_BUTTON',
  RETRY_BUTTON: 'RETRY_BUTTON',
  LOG_IN_HERE: 'LOG_IN_HERE',
  LOG_IN: 'LOG_IN',
  PLAY_AS_GUEST: 'PLAY_AS_GUEST',
  FOOTER_TEXT: 'FOOTER_TEXT',
  CHOOSE_PASSWORD: 'CHOOSE_PASSWORD',
  ALREADY_HAVE_ACCOUNT: 'ALREADY_HAVE_ACCOUNT',
  OR: 'OR',
  HRI: 'HRI',
  SGA: 'SGA',
  TI: 'TI',
  MILLELACS: 'MILLELACS',
  ADMIRAL: 'ADMIRAL',
  FIRST_NAME: 'FIRST_NAME',
  LAST_NAME: 'LAST_NAME',
  CONFIRM_PASSWORD: 'CONFIRM_PASSWORD',
  RECEIVE_OFFERS_FROM: 'RECEIVE_OFFERS_FROM',
  HAVE_READ_AND_ACCEPT: 'HAVE_READ_AND_ACCEPT',
  AND: 'AND',
  RECEIVE_FURTHER_INFO_FROM_SGA: 'RECEIVE_FURTHER_INFO_FROM_SGA',
  RECEIVE_FURTHER_INFO_FROM_TI: 'RECEIVE_FURTHER_INFO_FROM_TI',
  RECEIVE_FURTHER_INFO_FROM_ADMIRAL: 'RECEIVE_FURTHER_INFO_FROM_ADMIRAL',
  RECEIVE_OFFERS_FROM_HRI: 'RECEIVE_OFFERS_FROM_HRI',
  RECEIVE_FURTHER_INFO_FROM_MILLELACS: 'RECEIVE_FURTHER_INFO_FROM_MILLELACS',
  SIGNING_UP: 'SIGNING_UP',
  EMAIL_ALREADY_EXISTS: 'EMAIL_ALREADY_EXISTS',
  COMPLETE: 'COMPLETE',
  PICK_REWARD: 'PICK_REWARD',
  SPIN_WHEEL: 'SPIN_WHEEL',
  OPEN_GAME: 'OPEN_GAME',
  SELECT_MODAL: 'SELECT_MODAL',
  OPEN: 'OPEN',
  GO: 'GO',
  OPEN_BUTTON: 'OPEN_BUTTON',
  SELECT_OPTION: 'SELECT_OPTION',
  TRIGGER_MOON: 'TRIGGER_MOON',
  SHOW: 'SHOW',
  COMING_SOON_TITLE: 'COMING_SOON_TITLE',
  CONNECTION_LOST_TITLE: 'CONNECTION_LOST_TITLE',
  INTERNET_CONNECTION: 'INTERNET_CONNECTION',
  REMOVE_FAVOURITE_GAME: 'REMOVE_FAVOURITE_GAME',
  ARE_YOU_SURE_REMOVE: 'ARE_YOU_SURE_REMOVE',
  CANCEL_FAVOURITE: 'CANCEL_FAVOURITE',
  AGE_RESTRICTION: 'AGE_RESTRICTION',
  DATE_OF_BIRTH: 'DATE_OF_BIRTH',
  COUNTRY: 'COUNTRY',
  STATE_PROVINCE: 'STATE_PROVINCE',
  CONSUMABLE_CLAIM_FAILED_ALERT: 'CONSUMABLE_CLAIM_FAILED_ALERT',
  ALREADY_CLAIMED_BONUS_OR_EXPIRED: 'ALREADY_CLAIMED_BONUS_OR_EXPIRED',
  SORRY: 'SORRY',
  EXTRA_PERCENTAGE: 'EXTRA_PERCENTAGE',
  WAS: 'WAS',
  ACCESS: 'ACCESS',
  FREE: 'FREE',
  GET: 'GET',
  YOUR: 'YOUR',
  EXPIRE_ON: 'EXPIRE_ON',
  BE_QUICK_AND_REDEEM_NOW: 'BE_QUICK_AND_REDEEM_NOW',
  INVALID_BODY_MESSAGE: 'INVALID_BODY_MESSAGE',
  SENDING_NEW_PASSWORD_REQUEST: 'SENDING_NEW_PASSWORD_REQUEST',
  TYPE_NEW_PASSWORD_BELOW: 'TYPE_NEW_PASSWORD_BELOW',
  PASSWORD_HAS_BEEN_RESET: 'PASSWORD_HAS_BEEN_RESET',
  NEW_PASSWORD: 'NEW_PASSWORD',
  THANKS: 'THANKS',
  PURCHASE_SUCCESSFUL: 'PURCHASE_SUCCESSFUL',
  ACCESS_GRANTED: 'ACCESS_GRANTED',
  ENJOY_EXCLUSIVE_COLLECTION_MESSAGE: 'ENJOY_EXCLUSIVE_COLLECTION_MESSAGE',
  COINS_HAVE_BEEN_ADDED_MESSAGE: 'COINS_HAVE_BEEN_ADDED_MESSAGE',
  DO_YOU_LIKE_OUR_GAME: 'DO_YOU_LIKE_OUR_GAME',
  PLEASE_RATE_US: 'PLEASE_RATE_US',
  ADD: 'ADD',
  CONFIRM_DELETE_CARD: 'CONFIRM_DELETE_CARD',
  YES: 'YES',
  APPLY: 'APPLY',
  CARD_TYPE: 'CARD_TYPE',
  CARD_HOLDER_NAME: 'CARD_HOLDER_NAME',
  CREDIT_CARD_NUMBER: 'CREDIT_CARD_NUMBER',
  EXPIRY_MONTH: 'EXPIRY_MONTH',
  EXPIRY_YEAR: 'EXPIRY_YEAR',
  CVV: 'CVV',
  CVV_NUMBER: 'CVV_NUMBER',
  CVV_OUTLINED_IN_GREEN: 'CVV_OUTLINED_IN_GREEN',
  CARD_NICKNAME: 'CARD_NICKNAME',
  MY: 'MY',
  PURCHASE: 'PURCHASE',
  COINS: 'COINS',
  BOOST: 'BOOST',
  TRANSACTION_COULD_NOT_BE_PROCESSED: 'TRANSACTION_COULD_NOT_BE_PROCESSED',
  CONTACT_CUSTOMER_SUPPORT: 'CONTACT_CUSTOMER_SUPPORT',
  TOTAL: 'TOTAL',
  PURCHASE_FLOW_GENERIC_ERROR: 'PURCHASE_FLOW_GENERIC_ERROR',
  PURCHASE_FLOW_CLOSE_ERROR: 'PURCHASE_FLOW_CLOSE_ERROR',
  YOU_MUST_ENTER_A_PROMO_CODE: 'YOU_MUST_ENTER_A_PROMO_CODE',
  KYC_LOCKOUT_TITLE: 'KYC_LOCKOUT_TITLE',
  KYC_LOCKOUT_MESSAGE: 'KYC_LOCKOUT_MESSAGE',
  KYC_LOCKOUT_BUTTON_LABEL: 'KYC_LOCKOUT_BUTTON_LABEL',
  PURCHASE_CANCELED: 'PURCHASE_CANCELED',
  STORE_CONNECTION_ERROR: 'STORE_CONNECTION_ERROR',
  ITEM_SKU_IS_MISSING: 'ITEM_SKU_IS_MISSING',
  RECEIPT_UNAVAILABLE: 'RECEIPT_UNAVAILABLE',
  VISIT_STORE: 'VISIT_STORE',
  QUICK_BUY: 'QUICK_BUY',
  TIME_REMAINING_PREFIX: 'TIME_REMAINING_PREFIX',
  FORGOT_PASSWORD: 'FORGOT_PASSWORD',
  PASSWORD: 'PASSWORD',
  LOGIN: 'LOGIN',
  SIGN_UP: 'SIGN_UP',
  TRY_LINKING_AGAIN: 'TRY_LINKING_AGAIN',
  UNABLE_TO_LINK_CARD: 'UNABLE_TO_LINK_CARD',
  SESSION_EXPIRED: 'SESSION_EXPIRED',
  PROCESSING: 'PROCESSING',
  YOUR_ACCOUNT_IS_NOW_LINKED: 'YOUR_ACCOUNT_IS_NOW_LINKED',
  ACCOUNT_LINKED_MESSAGE: 'ACCOUNT_LINKED_MESSAGE',
  CONFIRM_EMAIL: 'CONFIRM_EMAIL',
  SIMPLY_ENTER_UNIQUE_EMAIL: 'SIMPLY_ENTER_UNIQUE_EMAIL',
  AN_UNEXPECTED_ERROR: 'AN_UNEXPECTED_ERROR',
  FORM_INVALID_ERROR: 'FORM_INVALID_ERROR',
  AGE_UNDER_18_OR_FORM_INCOMPLETE_ERROR: 'AGE_UNDER_18_OR_FORM_INCOMPLETE_ERROR',
  LINK: 'LINK',
  TERMS_AGREE_MESSAGE: 'TERMS_AGREE_MESSAGE',
  TERMS_OF_SERVICE: 'TERMS_OF_SERVICE',
  EARN: 'EARN',
  WHEN_YOU_LINK_YOUR_ACCOUNT: 'WHEN_YOU_LINK_YOUR_ACCOUNT',
  LINK_YOUR_ACCOUNT_TODAY_AND_EARN: 'LINK_YOUR_ACCOUNT_TODAY_AND_EARN',
  FREE_COINS_AND_BENEFITS: 'FREE_COINS_AND_BENEFITS',
  LINK_ACCOUNT_TITLE: 'LINK_ACCOUNT_TITLE',
  LINK_ACCOUNT_BUTTON: 'LINK_ACCOUNT_BUTTON',
  BRONZE: 'BRONZE',
  PLATINUM: 'PLATINUM',
  SILVER: 'SILVER',
  GOLD: 'GOLD',
  DIAMOND: 'DIAMOND',
  LATER_BUTTON: 'LATER_BUTTON',
  NO_THANKS_BUTTON: 'NO_THANKS_BUTTON',
  RATE_NOW: 'RATE_NOW',
  LINK_ACCOUNT_DESCRIPTION: 'LINK_ACCOUNT_DESCRIPTION',
  LINK_CARD_TITLE: 'LINK_CARD_TITLE',
  LINK_CARD_SUBTITLE: 'LINK_CARD_SUBTITLE',
  LINK_CARD_SIGNUP: 'LINK_CARD_SIGNUP',
  LINK_CARD_SIGNUP_CTA: 'LINK_CARD_SIGNUP_CTA',
  LINK_CARD_OTP_CONSENTS: 'LINK_CARD_OTP_CONSENTS',
  REGISTER_CARD_OTP_CONSENTS: 'REGISTER_CARD_OTP_CONSENTS',
  UNITY_LINK_ACCOUNT_INFO_TITLE: 'UNITY_LINK_ACCOUNT_INFO_TITLE',
  UNITY_LINK_ACCOUNT_INFO_SUBTITLE: 'UNITY_LINK_ACCOUNT_INFO_SUBTITLE',
  LOYALTY_CARD_TITLE: 'LOYALTY_CARD_TITLE',
  PROPERTY_SELECTOR: 'PROPERTY_SELECTOR',
  NAME_LABEL: 'NAME_LABEL',
  RANK_LABEL: 'RANK_LABEL',
  POINTS_LABEL: 'POINTS_LABEL',
  PRIZE_LABEL: 'PRIZE_LABEL',
  REMAINING_TIME: 'REMAINING_TIME',
  YOUR_RANK: 'YOUR_RANK',
  UNRANKED: 'UNRANKED',
  SCORES: 'SCORES',
  HOURLY_TAB: 'HOURLY_TAB',
  WEEKLY_TAB: 'WEEKLY_TAB',
  DAILY_TAB: 'DAILY_TAB',
  GENERIC_LOGIN_ERROR: 'GENERIC_LOGIN_ERROR',
  TRY_AGAIN_LATER: 'TRY_AGAIN_LATER',
  GENERIC_MESSAGE_ERROR: 'GENERIC_MESSAGE_ERROR',
  GAME_UNAVAILABLE: 'GAME_UNAVAILABLE',
  ANOTHER_GAME_DOWNLOADING: 'ANOTHER_GAME_DOWNLOADING',
  DOWNLOADING_GAME: 'DOWNLOADING_GAME',
  PROGRESS: 'PROGRESS',
  FRIENDS: 'FRIENDS',
  TEST_SHARE: 'TEST_SHARE',
  FORGOT_PASSWORD_TITLE: 'FORGOT_PASSWORD_TITLE',
  ENTER_YOUR_REGISTERED_EMAIL: 'ENTER_YOUR_REGISTERED_EMAIL',
  ACCOUNT_HAS_BEEN_LINKED: 'ACCOUNT_HAS_BEEN_LINKED',
  INVALID_EMAIL: 'INVALID_EMAIL',
  LEVEL_UP_TEXT: 'LEVEL_UP_TEXT',
  FAVOURITES_GAMES_EXPIRED: 'FAVOURITES_GAMES_EXPIRED',
  GO_TO_VIP: 'GO_TO_VIP',
  FAVOURITES_ARE_FULL: 'FAVOURITES_ARE_FULL',
  FAVORITES_REQUIRED_LEVEL: 'FAVORITES_REQUIRED_LEVEL',
  SELECT_A_GAME_TO_REPLACE: 'SELECT_A_GAME_TO_REPLACE',
  DELETE_ACCOUNT_WARNING: 'DELETE_ACCOUNT_WARNING',
  EMAIL: 'EMAIL',
  PASSWORD_HINT: 'PASSWORD_HINT',
  PROMOTIONAL_EMAIL_OPTION: 'PROMOTIONAL_EMAIL_OPTION',
  NONE: 'NONE',
  REGISTER: 'REGISTER',
  INVALID_EMAIL_FORMAT: 'INVALID_EMAIL_FORMAT',
  EMAIL_REQUIRED: 'EMAIL_REQUIRED',
  PASSWORD_LENGTH: 'PASSWORD_LENGTH',
  PASSWORD_REQUIRED: 'PASSWORD_REQUIRED',
  NUMBER: 'NUMBER',
  YEAR: 'YEAR',
  MONTH: 'MONTH',
  DAY: 'DAY',
  DAYS: 'DAYS',
  PIN: 'PIN',
  CREDIT_CARD_MONTH: 'CREDIT_CARD_MONTH',
  CREDIT_CARD_YEAR: 'CREDIT_CARD_YEAR',
  PAYMENT_INFO_FIRST_NAME: 'PAYMENT_INFO_FIRST_NAME',
  PAYMENT_INFO_LAST_NAME: 'PAYMENT_INFO_LAST_NAME',
  PAYMENT_INFO_YEAR: 'PAYMENT_INFO_YEAR',
  PAYMENT_INFO_MONTH: 'PAYMENT_INFO_MONTH',
  PAYMENT_INFO_DAY: 'PAYMENT_INFO_DAY',
  PAYMENT_INFO_ADDRESS_LINE_1: 'PAYMENT_INFO_ADDRESS_LINE_1',
  PAYMENT_INFO_ADDRESS_LINE_2: 'PAYMENT_INFO_ADDRESS_LINE_2',
  PAYMENT_INFO_COUNTRY: 'PAYMENT_INFO_COUNTRY',
  PAYMENT_INFO_CITY: 'PAYMENT_INFO_CITY',
  PAYMENT_INFO_STATE: 'PAYMENT_INFO_STATE',
  PAYMENT_INFO_ZIP: 'PAYMENT_INFO_ZIP',
  PAYMENT_INFO_STATE_PROVINCE: 'PAYMENT_INFO_STATE_PROVINCE',
  PAYMENT_INFO_SAVE_YOUR_CARD: 'PAYMENT_INFO_SAVE_YOUR_CARD',
  PAYMENT_INFO_SELECT_METHOD: 'PAYMENT_INFO_SELECT_METHOD',
  LOCKED_DESCRIPTION: 'LOCKED_DESCRIPTION',
  ONE_TIME_PASSWORD_TITLE: 'ONE_TIME_PASSWORD_TITLE',
  ONE_TIME_PASSWORD_SENDING_REQUEST: 'ONE_TIME_PASSWORD_SENDING_REQUEST',
  ONE_TIME_PASSWORD_SENDING_REQUEST_ERROR: 'ONE_TIME_PASSWORD_SENDING_REQUEST_ERROR',
  ONE_TIME_PASSWORD_MESSAGE: 'ONE_TIME_PASSWORD_MESSAGE',
  ONE_TIME_PASSWORD_AUTENTICATED: 'ONE_TIME_PASSWORD_AUTENTICATED',
  ONE_TIME_PASSWORD_AUTENTICATION_ERROR: 'ONE_TIME_PASSWORD_AUTENTICATION_ERROR',
  ONE_TIME_PASSWORD_BUTTON_SUBMIT: 'ONE_TIME_PASSWORD_BUTTON_SUBMIT',
  ONE_TIME_PASSWORD_BUTTON_RESEND: 'ONE_TIME_PASSWORD_BUTTON_RESEND',
  TIER_BONUS_NOT_LINKED: 'TIER_BONUS_NOT_LINKED',
  BUY_NOW: 'BUY_NOW',
  SIDE_BAR_FAVORITE: 'SIDE_BAR_FAVORITE',
  SIDE_BAR_VIP_ROOM: 'SIDE_BAR_VIP_ROOM',
  SIDE_BAR_SLOT: 'SIDE_BAR_SLOT',
  SIDE_BAR_TABLE: 'SIDE_BAR_TABLE',
  SIDE_BAR_SPORTS: 'SIDE_BAR_SPORTS',
  // Settings Titles
  CUSTOMER_SUPPORT_TITLE: 'CUSTOMER_SUPPORT_TITLE',
  MESSAGE_SENT_TITLE: 'MESSAGE_SENT_TITLE',
  MESSAGE_SENT_ERROR_TITLE: 'MESSAGE_SENT_ERROR_TITLE',
  ACCOUNT_TITLE: 'ACCOUNT_TITLE',
  CHANGE_PASSWORD_TITLE: 'CHANGE_PASSWORD_TITLE',
  CHANGE_PASSWORD_SUCCESS_TITLE: 'CHANGE_PASSWORD_SUCCESS_TITLE',
  CHANGE_PASSWORD_ERROR_TITLE: 'CHANGE_PASSWORD_ERROR_TITLE',
  UPDATE_EMAIL_TITLE: 'UPDATE_EMAIL_TITLE',
  UPDATE_EMAIL_SUCCESS_TITLE: 'UPDATE_EMAIL_SUCCESS_TITLE',
  UPDATE_EMAIL_ERROR_TITLE: 'UPDATE_EMAIL_ERROR_TITLE',
  TERMS_AND_CONDITIONS_SETTING_TITLE: 'TERMS_AND_CONDITIONS_SETTING_TITLE',
  PRIVACY_POLICY_TITLE: 'PRIVACY_POLICY_TITLE',
  SETTINGS_TITLE: 'SETTINGS_TITLE',
  SETTINGS_COPYRIGHT: 'SETTINGS_COPYRIGHT',
  //STORE
  SELECT_COUNTRY: 'SELECT_COUNTRY',
  SELECT_STATE: 'SELECT_STATE',
  //LOGIN ERRORS
  GENERIC_LOGIN_ERROR_TITLE: 'GENERIC_LOGIN_ERROR_TITLE',
  GENERIC_LOGIN_ERROR_MESSAGE: 'GENERIC_LOGIN_ERROR_MESSAGE',
  FACEBOOK_LOGIN_ERROR: 'FACEBOOK_LOGIN_ERROR',
  FACEBOOK_ACCEPT_PERMISSION: 'FACEBOOK_ACCEPT_PERMISSION',
  GOOGLE_LOGIN_ERROR: 'GOOGLE_LOGIN_ERROR',
  GOOGLE_PLAY_SERVICE_ERROR: 'GOOGLE_PLAY_SERVICE_ERROR',
  APPLE_LOGIN_ERROR: 'APPLE_LOGIN_ERROR',
  APPLE_LOGIN_UPDATE_ERROR: 'APPLE_LOGIN_UPDATE_ERROR',
  //LOGIN FORM VALIDATION
  LOGIN_EMAIL_REQUIRED: 'LOGIN_EMAIL_REQUIRED',
  LOGIN_PASSWORD_REQUIRED: 'LOGIN_PASSWORD_REQUIRED',
  //Ad Block
  ADBLOCK_TITLE: 'ADBLOCK_TITLE',
  ADBLOCK_DESCRIPTION: 'ADBLOCK_DESCRIPTION',
  //Customer Support
  CUSTOMER_SUPPORT_FORM_TITLE: 'CUSTOMER_SUPPORT_FORM_TITLE',
  CUSTOMER_SUPPORT_FORM_BODY: 'CUSTOMER_SUPPORT_FORM_BODY',
  CUSTOMER_SUPPORT_FORM_SEND: 'CUSTOMER_SUPPORT_FORM_SEND',
  //Message Items
  REDEEM_MESSAGE_CONGRATS: 'REDEEM_MESSAGE_CONGRATS',
  REDEEM_MESSAGE_CONGRATS_TEXT: 'REDEEM_MESSAGE_CONGRATS_TEXT',
  //Validators
  VALIDATOR_PASSWORD: 'VALIDATOR_PASSWORD',
  VALIDATOR_NOT_EMPTY: 'VALIDATOR_NOT_EMPTY',
  VALIDATOR_LETTERS_ONLY: 'VALIDATOR_LETTERS_ONLY',
  VALIDATOR_NUMBERS_ONLY: 'VALIDATOR_NUMBERS_ONLY',
  VALIDATOR_WORDS: 'VALIDATOR_WORDS',
  VALIDATOR_POSTAL: 'VALIDATOR_POSTAL',
  VALIDATOR_ADULT_ONLY: 'VALIDATOR_ADULT_ONLY',
  VALIDATOR_CREDIT_CARD: 'VALIDATOR_CREDIT_CARD',
  VALIDATOR_CVV: 'VALIDATOR_CVV',
  //Push Notification
  PUSH_NOTIFICATION_TIME_BONUS_READY: 'PUSH_NOTIFICATION_TIME_BONUS_READY',
  PUSH_NOTIFICATION_TIME_BONUS_LOGIN: 'PUSH_NOTIFICATION_TIME_BONUS_LOGIN',
  PUSH_NOTIFICATION_MISSION_ALMOST: 'PUSH_NOTIFICATION_MISSION_ALMOST',
  PUSH_NOTIFICATION_MISSION_ALMOST_TEXT: 'PUSH_NOTIFICATION_MISSION_ALMOST_TEXT',
  //ODR
  ODR_DOWNLOAD_ERROR: 'ODR_DOWNLOAD_ERROR',
  //Missions
  GET_LEADERBOARDS_ERROR: 'GET_LEADERBOARDS_ERROR',
  ADD_FAVOURITE_ERROR: 'ADD_FAVOURITE_ERROR',
  REMOVE_FAVOURITE_ERROR: 'REMOVE_FAVOURITE_ERROR',
  PANEL_NOT_SUPPORTED_ERROR: 'PANEL_NOT_SUPPORTED_ERROR',
  WIN_TEXT: 'WIN_TEXT',
  TIME_LEFT_TEXT: 'TIME_LEFT_TEXT',
  MYSTERY_REWARD: 'MYSTERY_REWARD',
  MISSION_DONE_CLAIM_REWARD_TOOLTIP: 'MISSION_DONE_CLAIM_REWARD_TOOLTIP',
  MISSION_ENDS_SOON_TOOLTIP: 'MISSION_ENDS_SOON_TOOLTIP',
  //Reward Center
  REWARD_CENTER_PROFILE: 'REWARD_CENTER_PROFILE',
  AVAILABLE_BALANCE: 'AVAILABLE_BALANCE',
  PURCHASE_ACCESS: 'PURCHASE_ACCESS',
  REDEEM_ACCESS: 'REDEEM_ACCESS',
  PLAY_NOW: 'PLAY_NOW',
  DETAILS: 'DETAILS',
  LOYALTY_POINTS: 'LOYALTY_POINTS',
  LINK_ACCOUNT_ONBOARDING_TITLE: 'LINK_ACCOUNT_ONBOARDING_TITLE',
  LINK_ACCOUNT_ONBOARDING_SUBTITLE: 'LINK_ACCOUNT_ONBOARDING_SUBTITLE',
  LINK_OR_REGISTER_BENEFITS_CLICK_HERE: 'LINK_OR_REGISTER_BENEFITS_CLICK_HERE',
  LINK_OR_REGISTER_BENEFITS_CHECK: 'LINK_OR_REGISTER_BENEFITS_CHECK',
  REWARD_CENTER_OFFER_DETAILS_TITLE: 'REWARD_CENTER_OFFER_DETAILS_TITLE',
  REWARD_CENTER_INITREWARDS_FAILED_MESSAGE: 'REWARD_CENTER_INITREWARDS_FAILED_MESSAGE',
  TIER: 'TIER',
  //Limited Time Offer
  ONLY: 'ONLY',
  CURRENCY_SIGN: 'CURRENCY_SIGN',
  //CHANGE PASSWORD
  CURRENT_PASSWORD: 'CURRENT_PASSWORD',
  UPDATE_PASS_BUTTON: 'UPDATE_PASS_BUTTON',
  //SIGNUP
  FIRST_NAME_REQUIRED: 'FIRST_NAME_REQUIRED',
  LAST_NAME_REQUIRED: 'LAST_NAME_REQUIRED',
  // Timer
  TIMER_LEFT_DAY: 'TIMER_LEFT_DAY',
  TIMER_LEFT_HOUR: 'TIMER_LEFT_HOUR',
  TIMER_LEFT_MINUTE: 'TIMER_LEFT_MINUTE',
  // Must/Should Update
  UPDATE_AVAILABLE: 'UPDATE_AVAILABLE',
  UPDATE_REQUIRED: 'UPDATE_REQUIRED',
  UPDATE_NOW: 'UPDATE_NOW',
  UPDATE_TEXT_SHOULD: 'UPDATE_TEXT_SHOULD',
  UPDATE_TEXT_MUST: 'UPDATE_TEXT_MUST',
  //EVENTS
  EVENTS_ERROR_TOOLTIP: 'EVENTS_ERROR_TOOLTIP',
  EVENTS_COMING_TOOLTIP: 'EVENTS_COMING_TOOLTIP',
  EVENTS_MUST_UPDATE_TOOLTIP: 'EVENTS_MUST_UPDATE_TOOLTIP',
  EVENTS_ENTRIES_TITLE: 'EVENTS_ENTRIES_TITLE',
  EVENTS_COMPLETED_LABEL: 'EVENTS_COMPLETED_LABEL',
  EVENTS_HERE_LABEL: 'EVENTS_HERE_LABEL',
  //SOCIAL
  SOCIAL_MESSAGES_TITLE: 'SOCIAL_MESSAGES_TITLE',
  SOCIAL_FRIENDS_INVITE_TITLE: 'SOCIAL_FRIENDS_INVITE_TITLE',
  SOCIAL_INVITE_SCREEN_DESCRIPTION: 'SOCIAL_INVITE_SCREEN_DESCRIPTION',
  SOCIAL_INVITE_SCREEN_FOOTER: 'SOCIAL_INVITE_SCREEN_FOOTER',
  SOCIAL_INVITE_SCREEN_BUTTON: 'SOCIAL_INVITE_SCREEN_BUTTON',
  SOCIAL_GUEST_USER_TITLE: 'SOCIAL_GUEST_USER_TITLE',
  SOCIAL_LIMIT_REACHED_TITLE: 'SOCIAL_LIMIT_REACHED_TITLE',
  SOCIAL_LIMIT_REACHED_DESCRIPTION_LEFT: 'SOCIAL_LIMIT_REACHED_DESCRIPTION_LEFT',
  SOCIAL_LIMIT_REACHED_DESCRIPTION_RIGHT: 'SOCIAL_LIMIT_REACHED_DESCRIPTION_RIGHT',
  SOCIAL_INVITE_SCREEN_DESCRIPTION_WEB: 'SOCIAL_INVITE_SCREEN_DESCRIPTION_WEB',
  SOCIAL_INVITE_SCREEN_LINK_COPIED: 'SOCIAL_INVITE_SCREEN_LINK_COPIED',
  //PRELOADER
  PRELOADER_DESCRIPTION: 'PRELOADER_DESCRIPTION'
};