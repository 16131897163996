import _defineProperty from "/var/jenkins/workspace/Web Clients and Images - Production/Code/WEB CLIENT - GTP 2.0 Deploy/platforms/web/node_modules/babel-preset-react-app/node_modules/@babel/runtime/helpers/esm/defineProperty";
import { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { ACTIONS_COMMANDS_ASSETS_CATEGORY_TYPE, ACTION_COMMANDS_MODAL_TYPE, ACTION_COMMANDS_TYPE } from '../../../api/ActionCommandsInterface/constants/types';
import { EVENT_EMITTER_TYPE } from '../../../constants';
import Emitter from '../../../services/EmitterService';
import QueryParamProxy from '../../../utils/queryParamProxy';
import { generateButtonsRendering } from '../utils/generateButtonsRendering';
import routeParamConstants from '../../../utils/routeParamConstants';
import { ACTION_COMMANDS_EVENT_TYPE } from '../../../api/ActionCommandsInterface/constants';
import screens from '../..';

var useDynamicPopups = function useDynamicPopups() {
  var _useSelector = useSelector(function (state) {
    return state.actionCommands.actions.find(function (action) {
      var _action$payload;

      return (action === null || action === void 0 ? void 0 : (_action$payload = action.payload) === null || _action$payload === void 0 ? void 0 : _action$payload.screen) === screens.Home;
    });
  }),
      modal = _useSelector.modal;

  var modalImageSource = useSelector(function (state) {
    var _state$assetsPackage, _state$assetsPackage$;

    return (_state$assetsPackage = state.assetsPackage) === null || _state$assetsPackage === void 0 ? void 0 : (_state$assetsPackage$ = _state$assetsPackage.assets[ACTIONS_COMMANDS_ASSETS_CATEGORY_TYPE[modal === null || modal === void 0 ? void 0 : modal.modalType]]) === null || _state$assetsPackage$ === void 0 ? void 0 : _state$assetsPackage$.MODAL_BACKGROUND;
  });
  var buttonsRendering = modal ? generateButtonsRendering(modal) : null;
  useEffect(function () {
    if (modal) {
      var _modal$onDisplayActio, _modal$onDisplayActio2, _modal$onDisplayActio3;

      Emitter.emit(EVENT_EMITTER_TYPE.ACTION_COMMANDS, {
        actionType: (_modal$onDisplayActio = modal.onDisplayAction) === null || _modal$onDisplayActio === void 0 ? void 0 : _modal$onDisplayActio.actionType,
        data: {
          event: ACTION_COMMANDS_EVENT_TYPE.DISPLAY_APP_MODAL,
          modalType: modal.modalType,
          triggerExecution: (_modal$onDisplayActio2 = modal.onDisplayAction) === null || _modal$onDisplayActio2 === void 0 ? void 0 : _modal$onDisplayActio2.triggerExecution,
          payload: (_modal$onDisplayActio3 = modal.onDisplayAction) === null || _modal$onDisplayActio3 === void 0 ? void 0 : _modal$onDisplayActio3.payload
        }
      });
    } else {
      QueryParamProxy.modalPromiseAccept();
      QueryParamProxy.setQueryParams(_defineProperty({}, routeParamConstants.PARAM_MODAL, undefined));
    }

    return function () {
      Emitter.emit(EVENT_EMITTER_TYPE.ACTION_COMMANDS, {
        actionType: ACTION_COMMANDS_TYPE.CLEAR_ACTION_COMMAND
      });
      QueryParamProxy.modalPromiseAccept();
    };
  }, [modal]);
  return {
    modalImageSource: modalImageSource,
    showLinkAccountButtons: (modal === null || modal === void 0 ? void 0 : modal.modalType) === ACTION_COMMANDS_MODAL_TYPE.SAVE_YOUR_PROGRESS,
    buttonsRendering: buttonsRendering,
    modalType: modal === null || modal === void 0 ? void 0 : modal.modalType,
    onDisplayAction: modal === null || modal === void 0 ? void 0 : modal.onDisplayAction
  };
};

export default useDynamicPopups;