import React from 'react';
import styled from 'styled-components';
import useFriendsInvite from 'shared/screens/Social/hooks/useFriendsInvite';
import InviteScreen from './components/InviteScreen';
import LimitReachedScreen from './components/LimitReachedScreen';
import NotificationScreen from './components/NotificationScreen';

const FriendsInvite = () => {
  const friendsInviteShared = useFriendsInvite();

  return (
    <>
      <Container>
        <InviteScreen {...friendsInviteShared} />
        <LimitReachedScreen {...friendsInviteShared} />
        <NotificationScreen {...friendsInviteShared} />
      </Container>
    </>
  );
};

export default FriendsInvite;

const Container = styled.div`
  display: flex;
  flex: 1;
  justify-content: center;
  align-items: center;
`;

