/** */
import ENVIRONMENT_VARIABLES from '../environmentVariables';

var getUrl = function getUrl() {
  return "https://".concat(ENVIRONMENT_VARIABLES.CDN_ROOT);
};

var getCdnPath = function getCdnPath() {
  var CDN_ROOT = getUrl();
  return "".concat(CDN_ROOT, "/app/images");
};

var PREFIX = function PREFIX() {
  return getCdnPath();
};

export default {
  LOBBY_ADVERTS: '/promotions/',
  NEWS_ITEMS: "".concat(PREFIX(), "/marketing/news/welcome/"),
  STORE_NEWS: "".concat(PREFIX(), "/marketing/news/coinstore/"),
  COIN_PACKAGE: "".concat(PREFIX(), "/marketing/coinpkg/"),
  CHALLENGES: "".concat(PREFIX(), "/marketing/challenges/"),
  RC_OFFERS: "".concat(PREFIX(), "/marketing/news/offers/"),
  SLOT_ICON_WEB: "".concat(PREFIX(), "/games/1x1/"),
  SLOT_ICON_MOBILE: "".concat(PREFIX(), "/games/1x1/"),
  NEW_GAMES: "".concat(PREFIX(), "/games/long/"),
  COIN_STORE: "".concat(PREFIX(), "/coinstore/"),
  UI_WEB: "".concat(PREFIX(), "/general/"),
  REWARD_ASSETS: "".concat(PREFIX(), "/offers/ui/"),
  REWARD_IMAGES: "".concat(PREFIX(), "/offers/images/"),
  GAME_ROOM: "".concat(PREFIX(), "/gamerooms/"),
  ICON: "".concat(PREFIX(), "/icons/"),
  AUDIO: "".concat(PREFIX(), "/audio/"),
  MUSIC: "".concat(PREFIX(), "/audio/Music/"),
  AMBIENCE: "".concat(PREFIX(), "/audio/Ambience/"),
  BACKGROUND: "".concat(PREFIX(), "/backgrounds/"),
  LOBBY: "".concat(PREFIX(), "/lobby/"),
  EFFECTS: "".concat(PREFIX(), "/effects/"),
  LEADERBOARDS: "".concat(PREFIX(), "/leaderboards/"),
  PROPERTY: "".concat(PREFIX(), "/properties/"),
  LEVEL_UP: "".concat(PREFIX(), "/levelup/"),
  REWARD_CENTER_THUMBNAILS: "".concat(PREFIX(), "/offers/images/"),
  REWARD_CENTER_NEWS: "".concat(PREFIX(), "/marketing/news/offers/"),
  REWARD_CENTER_OFFERS_UI: "".concat(PREFIX(), "/offers/ui/"),
  LIVE_PANELS: "".concat(PREFIX(), "/marketing/live-panel/")
};