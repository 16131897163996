import React, { Component } from 'react';
import styled from 'styled-components';
import { Form } from 'react-bootstrap';
import SPasswordInput from 'shared/components/PasswordInput';
import generateRender from 'shared/utils/generateRender';
import styles from './styles';
import theme from 'shared/assets/style/theme';

const PasswordInputDefault = styled(Form.Control).attrs({
  className: 'transparentInput',
})``;

const PasswordInputMillelacs = styled(Form.Control)`
  background: rgb(0 39 62);
  border-radius: 0.6em;
  height: 42px;
  border: none;
  color: ${theme.palette.common[1]};
  ::placeholder,
  ::-webkit-input-placeholder {
    color: ${theme.palette.common[1]};
  }
  &:focus {
    background: rgb(0 39 62);
    border-radius: 0.6em;
    border: none;
    color: ${theme.palette.common[1]};
  }
`;

const PasswordFormInput = generateRender({
  default: PasswordInputDefault,
  millelacs: PasswordInputMillelacs,
});

export default class PasswordInput extends Component {
  render() {
    const { navigation, customPeekerStyle } = this.props;
    return (
      <SPasswordInput
        navigation={navigation}
        render={args => {
          const { showPassword, showPasswordToggleHandle } = args;
          return (
            <>
              <PasswordFormInput type={!showPassword ? 'password' : 'text'} {...this.props} />
              <div style={customPeekerStyle ? customPeekerStyle : styles.peeker} onClick={showPasswordToggleHandle}>
                {!showPassword && <img src={require(asset`Icon_Show.png`)} />}
                {showPassword && <img src={require(asset`Icon_Hide.png`)} />}
              </div>
            </>
          );
        }}
      />
    );
  }
}
